import Web3 from 'web3';
import { ethers } from 'ethers';
import { broadcastTransaction, getTransactionStatus } from '../../api';
import mydidContractABI from '/assets/mydidContractABI.json';

const web3 = new Web3(process.env.VUE_APP_WEB3_PROVIDER);

const mydidContract = new web3.eth.Contract(
  mydidContractABI,
  process.env.VUE_APP_MYDID_CONTRACT_ADDR
);

export async function broadcastEncode(signerAddress, methodName, ...args) {
  const nonce = await mydidContract.methods.nonce(signerAddress).call();

  const methodHex = web3.utils.asciiToHex(methodName);

  const message = ethers.solidityPacked(
    ['address', 'uint', ...args.map((el) => el.type), 'bytes'],
    [
      process.env.VUE_APP_MYDID_CONTRACT_ADDR,
      nonce,
      ...args.map((el) => el.value),
      methodHex,
    ]
  );

  return message;
}

export async function broadcastEncodeNext(signerAddress, methodName, ...args) {
  const nonce =
    Number(await mydidContract.methods.nonce(signerAddress).call()) + 1;

  const methodHex = web3.utils.asciiToHex(methodName);

  const message = ethers.solidityPacked(
    ['address', 'uint', ...args.map((el) => el.type), 'bytes'],
    [
      process.env.VUE_APP_MYDID_CONTRACT_ADDR,
      nonce,
      ...args.map((el) => el.value),
      methodHex,
    ]
  );

  return message;
}

export async function sendTransaction(addr, methodName, methodArgs, signature) {
  const transactionId = (
    await broadcastTransaction({
      signerAddress: addr,
      methodName,
      methodArgs,
      signature,
    })
  ).data.id;

  let transactionStatus = null;

  // waiting transacion completion
  try {
    do {
      transactionStatus = (await getTransactionStatus(transactionId)).data
        .transaction;

      if (
        transactionStatus &&
        !transactionStatus.sent &&
        !transactionStatus.failed
      )
        await new Promise((r) => setTimeout(r, 2000));
    } while (
      !transactionStatus ||
      (transactionStatus &&
        !transactionStatus.sent &&
        !transactionStatus.failed)
    );
  } catch (e) {
    return { status: 'fail', error: e };
  }

  if (transactionStatus.sent) {
    return { status: 'success' };
  } else if (transactionStatus.failed)
    return {
      status: 'fail',
      error: `broadcaster not able to send transaction`,
    };
}
