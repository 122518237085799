<template>
  <div>
    <div class="fixed top-0 left-0 w-full h-full z-10">
      <div class="absolute w-full h-full bg-black bg-opacity-20"></div>
    </div>
    <div
      v-if="
        !waitingSignature &&
        !waitingLinkCreation &&
        !waitingTransaction &&
        !deleteSuccessful &&
        !displayQrCode &&
        !displayQrCodeVerif &&
        !displayDateSelector
      "
    >
      <div
        class="fixed w-modal-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-5 py-4 border rounded-xl shadow-md z-10"
      >
        <div v-if="templateData" class="flex items-center">
          <icon :name="`badge-${type.toLowerCase()}`" class="ml-2 my-2 mr-2" />
          <p class="text-lg font-bold my-2">
            {{ templateLabel }}
          </p>
          <div class="grow"></div>
          <div
            @click="triggerCloseDisplay"
            class="text-xl text-gray-400 mr-2 cursor-pointer"
          >
            <icon name="cross" />
          </div>
        </div>
        <div v-if="templateData" class="flex gap-10 p-10">
          <div v-if="type != 'Role'">
            <TemplatePreview
              v-if="templateData && currentIssuerInfo"
              :badgeImage="templateData.image"
              :badgeName="templateData.name"
              :badgeLocation="
                type == 'Participation'
                  ? templateData.eventDetails.location.split(', ')[0]
                    ? templateData.eventDetails.location.split(', ')[0]
                    : $t('templateDisplay.virtualTxt')
                  : null
              "
              :badgeStartDate="
                type == 'Participation'
                  ? templateData.eventDetails.startDate
                  : null
              "
              :badgeEndDate="
                type == 'Participation'
                  ? templateData.eventDetails.endDate
                  : null
              "
              :badgeSessions="templateSessions"
              :issuerName="currentIssuerInfo.profile.name"
              :formatXl="true"
              :type="type"
            />
          </div>
          <div
            v-else
            class="w-[300px] flex items-center justify-center relative overflow-hidden"
          >
            <div
              class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -z-10"
            >
              <img
                :src="require('/assets/images/role_background_image.png')"
                class="w-80 max-w-none"
              />
            </div>
            <TemplateRolePreview
              :name="templateData.name"
              :emoji="templateData.image"
              :color="templateData.hexColorRef"
            ></TemplateRolePreview>
          </div>
          <div class="grow">
            <p
              v-if="
                ['Basic', 'Community', 'Participation', 'Role'].includes(type)
              "
              class="text-xl font-bold mt-3 mb-5"
            >
              {{ templateData.name }}
            </p>
            <p
              v-if="['Membership'].includes(type)"
              class="text-xl font-bold mt-3 mb-5"
            >
              {{ $t('templateDisplay.membershipDefaultTitle') }}
            </p>
            <div v-if="['Role'].includes(type)" class="flex text-sm mb-5">
              <div class="flex-none w-40 text-gray-400">
                <icon name="color" class="fill-gray-400 mr-1.5" />{{
                  $t('templateDisplay.colorTxt')
                }}
              </div>
              <div
                class="w-4 h-4 inline-flex rounded-full cursor-pointer ml-0.5 mt-0.5"
                :style="`background-color:${templateData.hexColorRef}`"
              ></div>
            </div>
            <div v-if="['Role'].includes(type)" class="flex text-sm mb-5">
              <div class="flex-none w-40 text-gray-400">
                <icon name="smiley" class="fill-transparent mr-1.5" />{{
                  $t('templateDisplay.emojiTxt')
                }}
              </div>
              <img :src="templateData.image" class="w-5" />
            </div>
            <div class="flex text-sm mb-5">
              <div class="flex-none w-40 text-gray-400">
                <icon name="text-align-left" class="fill-gray-400 mr-1.5" />{{
                  $t('templateDisplay.descriptionTxt')
                }}
              </div>
              <div class="grow">{{ templateData.description }}</div>
            </div>
            <div
              v-if="
                ['Basic', 'Community', 'Participation', 'Role'].includes(type)
              "
              class="flex text-sm mb-5"
            >
              <div class="flex-none w-40 text-gray-400">
                <icon name="checklist" class="fill-gray-400 mr-1.5" />{{
                  $t('templateDisplay.criteriaTxt')
                }}
              </div>
              <div class="grow">{{ templateData.criteria }}</div>
            </div>
            <div
              v-if="['Participation'].includes(type)"
              class="flex text-sm mb-5"
            >
              <div class="flex-none w-40 text-gray-400">
                <icon name="calendar" class="fill-gray-400 mr-1.5" />{{
                  $t('templateDisplay.startDateTxt')
                }}
              </div>
              <div class="grow">
                {{
                  templateData.eventDetails.startDate
                    .replace('T', ' ')
                    .split('.')[0]
                }}
              </div>
            </div>
            <div
              v-if="['Participation'].includes(type)"
              class="flex text-sm mb-5"
            >
              <div class="flex-none w-40 text-gray-400">
                <icon name="calendar" class="fill-gray-400 mr-1.5" />{{
                  $t('templateDisplay.endDateTxt')
                }}
              </div>
              <div class="grow">
                {{
                  templateData.eventDetails.endDate
                    .replace('T', ' ')
                    .split('.')[0]
                }}
              </div>
            </div>
            <div
              v-if="['Participation'].includes(type)"
              class="flex text-sm mb-5"
            >
              <div class="flex-none w-40 text-gray-400">
                <icon name="map-pin" class="fill-gray-400 mr-1.5" />{{
                  $t('templateDisplay.locationTxt')
                }}
              </div>
              <div class="grow">
                {{
                  templateData.eventDetails.location
                    ? templateData.eventDetails.location
                    : $t('templateDisplay.virtualTxt')
                }}
              </div>
            </div>
            <div
              v-if="['Basic', 'Role'].includes(type)"
              class="flex text-sm mb-5"
            >
              <div class="flex-none w-40 text-gray-400">
                <icon name="calendar-check" class="fill-gray-400 mr-1.5" />{{
                  $t('templateDisplay.validityTxt')
                }}
              </div>
              <div class="grow">
                {{ templateValidityFormatted }}
              </div>
            </div>
            <div v-if="['Basic'].includes(type)" class="flex text-sm mb-5">
              <div class="flex-none w-40 text-gray-400">
                <icon name="clock-check" class="fill-gray-400 mr-1.5" />{{
                  $t('templateDisplay.timestampTxt')
                }}
              </div>
              <div class="grow">
                {{
                  templateData.timestamped
                    ? $t('templateDisplay.yesTxt')
                    : $t('templateDisplay.noTxt')
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <div v-if="['Participation'].includes(type)">
            <p
              v-if="openSession && templateData.eventDetails.limit != -1"
              class="text-gray-400 ml-2 mr-4 my-2"
            >
              {{
                templateData.eventDetails.limit -
                openSession.didHistory.length +
                '/' +
                templateData.eventDetails.limit +
                ' ' +
                $t('templateDisplay.remainingTxt')
              }}
            </p>
            <p
              v-else-if="openSession && templateData.eventDetails.limit == -1"
              class="text-gray-400 ml-2 mr-4 my-2"
            >
              {{
                openSession.didHistory.length +
                ' ' +
                $t('templateDisplay.distributedTxt')
              }}
            </p>
          </div>
          <div class="grow"></div>
          <button
            v-if="['Basic', 'Role'].includes(type)"
            @click="sendBadge"
            class="text-sm bg-white border shadow-sm font-medium rounded-lg cursor-pointer py-1.5 pl-2 pr-2.5 ml-auto mr-3"
          >
            <icon name="paper-plane" class="mr-1.5" />{{
              $t('templateDisplay.sendButton')
            }}
          </button>
          <button
            v-if="['Participation', 'Membership'].includes(type)"
            @click="sendBadge"
            class="text-sm bg-white border shadow-sm font-medium rounded-lg cursor-pointer py-1.5 pl-2 pr-2.5 ml-auto mr-3"
          >
            <icon name="mail" class="mr-1.5" />{{
              $t('templateDisplay.distributeButton')
            }}
          </button>
          <button
            v-if="['Participation', 'Membership'].includes(type)"
            @click="triggerQrCode"
            class="text-sm bg-white border shadow-sm font-medium rounded-lg cursor-pointer py-1.5 pl-2 pr-2.5 ml-auto mr-3"
          >
            <icon name="qr-code" class="mr-1.5" />{{
              $t('templateDisplay.getLinkButton')
            }}
          </button>
          <button
            v-if="
              ['Basic', 'Participation', 'Membership', 'Role'].includes(type)
            "
            @click="generateCustomLink() && (showMore = false)"
            class="text-sm bg-white border shadow-sm font-medium rounded-lg cursor-pointer py-1.5 pl-2 pr-2.5 ml-auto mr-3"
          >
            <icon name="link" class="mr-1.5" />{{
              $t('templateDisplay.generateLinkButton')
            }}
          </button>
          <button
            v-if="['Community'].includes(type)"
            @click="triggerEdit() && (showMore = false)"
            class="text-sm bg-white border shadow-sm font-medium rounded-lg cursor-pointer py-1.5 pl-2 pr-2.5 ml-auto mr-3"
          >
            <icon name="edit-pencil" class="mr-1.5" />{{
              $t('templateDisplay.editButton')
            }}
          </button>
          <button
            @click="showMore = !showMore"
            class="text-sm bg-white border shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2 ml-auto mr-3"
            click-outside-ignore-moremenu
          >
            <icon name="more-vertical" />
          </button>
          <div v-if="showMore" class="relative">
            <div
              class="absolute w-[15rem] bottom-5 -left-[15.8rem] bg-white px-3 py-2 border rounded-xl shadow-md"
              v-click-outside="
                () => {
                  showMore = false;
                }
              "
              click-outside-ignore-moremenu-target
            >
              <p
                @click="generateVerification() && (showMore = false)"
                class="text-sm font-medium cursor-pointer mb-1.5"
              >
                <icon name="user-square" class="mr-1.5" />{{
                  $t('templateDisplay.generateVerifierQrcode')
                }}
              </p>
              <p
                v-if="
                  ['Basic', 'Participation', 'Membership', 'Role'].includes(
                    type
                  )
                "
                @click="triggerEdit() && (showMore = false)"
                class="text-sm font-medium cursor-pointer"
              >
                <icon name="edit-pencil" class="mr-1.5" />{{
                  $t('templateDisplay.editButton')
                }}
              </p>
              <p
                v-if="['Basic'].includes(type) && isMydidIssuer"
                @click="generateOpenLink() && (showMore = false)"
                class="text-sm font-medium cursor-pointer mt-2"
              >
                <icon name="link" class="mr-1.5" />{{
                  $t('templateDisplay.generateCustomLinkButton')
                }}
              </p>
              <p
                v-if="['Basic'].includes(type) && isMydidIssuer"
                @click="(displayDateSelector = true) && (showMore = false)"
                class="text-sm font-medium cursor-pointer mt-2"
              >
                <icon name="link" class="mr-1.5" />{{
                  $t('templateDisplay.generateCustomLinkLimitedButton')
                }}
              </p>
              <p
                v-if="
                  ['Basic'].includes(type) &&
                  isMydidIssuer &&
                  sessionBuilders.length == 0
                "
                @click="generateSessionBuilder() && (showMore = false)"
                class="text-sm font-medium cursor-pointer mt-2"
              >
                <icon name="key" class="w-4 mr-2" />{{
                  $t('templateDisplay.generateApiKey')
                }}
              </p>
              <p
                v-if="
                  ['Basic'].includes(type) &&
                  isMydidIssuer &&
                  sessionBuilders.length > 0
                "
                @click="showSessionBuilderApiKey() && (showMore = false)"
                class="text-sm font-medium cursor-pointer mt-2"
              >
                <icon name="key" class="w-4 mr-2" />{{
                  $t('templateDisplay.copyApiKey')
                }}
              </p>
              <div
                v-if="['Basic', 'Participation', 'Role'].includes(type)"
                class="border-b my-2"
              ></div>
              <p
                @click="triggerDelete() && (showMore = false)"
                v-if="
                  ['Basic', 'Community', 'Participation', 'Role'].includes(type)
                "
                class="text-sm text-red-500 font-medium cursor-pointer"
              >
                <icon name="trash" class="fill-red-500 mr-1.5" />{{
                  $t('templateDisplay.deleteButton')
                }}
              </p>
            </div>
          </div>
        </div>
        <!-- DEV ONLY -->
        <!-- <div v-if="delegatedSessions && delegatedSessions.length > 0">
          <p>DEV INFO - BADGE BOT SESSIONS</p>
          <div v-for="session in delegatedSessions" class="flex gap-4">
            <p class="text-xs">{{ session.dynamicLink }}</p>
            <p class="text-xs">
              {{ session.delegationBadge.issuanceDate }} ->
              {{ session.delegationBadge.expirationDate }}
            </p>
            <p class="text-xs">did limit : {{ session.didLimit }}</p>
            <p class="text-xs">active : {{ session.active }}</p>
          </div>
        </div> -->
      </div>
    </div>
    <div v-else-if="waitingSignature">
      <SignatureHandler
        @ack="waitingSignatureAck = false"
        @close="waitingSignature = false"
        ref="signatureHandlerRef"
      >
        <div v-if="!waitingSignatureAck" class="mt-5 mb-10">
          <div class="justify-center mb-5">
            <Vue3Lottie
              :animationLink="require('/assets/images/loader-app.jpg')"
              :height="160"
              :width="160"
              :speed="0.7"
            />
          </div>
          <p
            v-if="waitingSignature == 'signDelete'"
            class="text-center text-xl font-bold px-20 mb-1"
          >
            {{
              $t('templateDisplay.confirmDeleteTxt') +
              ' ' +
              templateLabel.toLowerCase()
            }}
          </p>
          <p
            v-if="waitingSignature == 'signDelegation'"
            class="text-center text-xl font-bold px-20 mb-1"
          >
            {{ $t('templateDisplay.confirmDelegationCreationTxt') }}
          </p>
          <p class="text-center px-10 mb-4">
            {{ $t('templateDisplay.onMydidAppTxt') }}
          </p>
        </div>
        <div v-else class="mt-5 mb-10">
          <div class="justify-center mb-5">
            <Vue3Lottie
              :animationLink="require('/assets/images/loader-wait-app.jpg')"
              :height="100"
              :width="100"
              :speed="1"
            />
          </div>
          <p class="text-center text-xl font-bold px-20 mb-4">
            {{ $t('other.waitingAppConnection') }}
          </p>
        </div></SignatureHandler
      >
    </div>
    <div v-else-if="waitingTransaction">
      <BasicSmModal :noCloseOption="true">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-round.jpg')"
            :height="50"
            :width="50"
            :speed="1.5"
          />
        </div>
        <p class="text-center text-xl font-bold px-10">
          {{ $t('templateDisplay.deleteInProgess') }}
        </p>
      </BasicSmModal>
    </div>
    <div v-else-if="waitingLinkCreation">
      <BasicSmModal :noCloseOption="true">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-round.jpg')"
            :height="50"
            :width="50"
            :speed="1.5"
          />
        </div>
        <p class="text-center text-xl font-bold px-10">
          {{ $t('templateDisplay.linkInProgess') }}
        </p>
      </BasicSmModal>
    </div>
    <div v-show="deleteSuccessful">
      <BasicSmModal @close="triggerCloseDisplay">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-check.jpg')"
            :height="160"
            :width="160"
            :speed="0.5"
            :loop="false"
          />
        </div>
        <p class="text-center text-xl font-bold px-20 mb-5">
          {{ $t('templateDisplay.successDeleteTxt') }}
        </p>
      </BasicSmModal>
    </div>
    <div v-show="displayQrCode">
      <BasicSmModal @close="displayQrCode = false">
        <p class="text-center text-lg font-bold mb-4 p-5">
          {{ $t('templateDisplay.qrCodeTxt') }}
        </p>
        <div class="bg-white flex justify-center mb-10">
          <qrcode-vue :value="qrCodeLink" :size="200" level="H" :margin="1" />
        </div>
        <div class="flex w-3/4 border items-center rounded-md mb-3 mx-auto">
          <input
            type="text"
            rows="1"
            v-model="qrCodeLink"
            class="grow border-none rounded-xl"
            readonly="readonly"
          />
          <p @click="copyToClipboard" class="mx-2 cursor-pointer">
            <icon name="copy" />
          </p>
        </div>
        <div class="flex justify-center">
          <button
            @click="downloadQRCode"
            class="shadow-sm font-medium border rounded-lg cursor-pointer py-1.5 px-2.5"
          >
            <icon name="download" class="mr-1.5" />{{
              $t('templateDisplay.downloadButton')
            }}
          </button>
        </div>
      </BasicSmModal>
    </div>
    <div v-show="displayQrCodeVerif">
      <BasicSmModal @close="displayQrCodeVerif = false">
        <p class="text-center text-lg font-bold mb-4 p-5">
          {{ $t('templateDisplay.qrCodeVerifTxt') }}
        </p>
        <div class="bg-white flex justify-center mb-10">
          <qrcode-vue
            :value="qrCodeVerifValue"
            :size="200"
            level="L"
            :margin="1"
          />
        </div>
      </BasicSmModal>
    </div>
    <div v-show="displayDateSelector">
      <BasicSmModal @close="displayDateSelector = false" class="text-center">
        <p class="mb-3">Sélectionner une période ci-dessous :</p>
        <datepicker
          class="border-none"
          :inline="true"
          :enable-time-picker="false"
          :start-time="[
            { hours: 0, minutes: 0 },
            { hours: 23, minutes: 59 },
          ]"
          range
          auto-apply
          v-model="dateRange"
          @update:model-value="
            () => {
              templateStartDate = startDatePickerValue;
              showStartDatePicker = false;
            }
          "
        />
        <div v-if="dateRange && dateRange.length == 2" class="mt-3">
          <p class="text-sm mb-3">
            {{
              new Date(dateRange[0]).toLocaleDateString() +
              ' - ' +
              new Date(dateRange[1]).toLocaleDateString()
            }}
          </p>
          <button
            @click="generateLimitedOpenLink"
            class="text-sm bg-white border shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2"
          >
            Sélectionner
          </button>
        </div>
      </BasicSmModal>
    </div>
  </div>
</template>
<style scoped>
.dp__main::v-deep .dp__action_select {
  background-color: rgb(79 70 229) !important;
}
.dp__main::v-deep .dp__active_date {
  background-color: rgb(79 70 229) !important;
}
.dp__main::v-deep .dp__outer_menu_wrap {
  margin: auto !important;
}
</style>
<script>
import { ref, inject, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { createToast } from 'mosha-vue-toastify';
import { useI18n } from 'vue-i18n';
import TemplatePreview from '../../components/templates/TemplatePreview.vue';
import TemplateRolePreview from '../../components/templates/TemplateRolePreview.vue';
import TemplateLabel from '../../components/templates/TemplateLabel.vue';
import SignatureHandler from '../../components/SignatureHandler.vue';
import BasicSmModal from '../../components/BasicSmModal.vue';
export default {
  props: {
    templateData: Object,
    templateSessions: Object,
    templateHash: String,
    index: Number,
    type: String,
  },
  setup(props, context) {
    const store = useStore();
    const utils = inject('utils');
    const api = inject('api');
    const { t } = useI18n();

    const currentIssuerInfo = computed(
      () => store.getters.getCurrentIssuerInfo
    );
    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);
    const currentAddress = computed(() => store.getters.getCurrentAddress);

    const templateLabel = ref(utils.templateTypeToLabel(props.type, useI18n()));
    const signatureHandlerRef = ref();
    const waitingSignature = ref(null);
    const waitingSignatureAck = ref(false);
    const waitingLinkCreation = ref(false);
    const waitingTransaction = ref(false);
    const deleteSuccessful = ref(false);
    const displayQrCode = ref(false);
    const displayQrCodeVerif = ref(false);
    const qrCodeLink = ref('');
    const qrCodeVerifValue = ref('');
    const displayDateSelector = ref(false);
    const dateRange = ref();
    const showMore = ref(false);
    const delegatedSessions = ref(null);
    const sessionBuilders = ref(null);
    const templateCategory = ref(
      ['Basic', 'Community', 'Participation', 'Membership', 'Role'].indexOf(
        props.type
      )
    );
    const openSession = ref(null);
    const isMydidIssuer = ref(
      currentIssuerDid.value == process.env.VUE_APP_MYDID_ISSUER_DID
    );

    watch(waitingSignature, () => {
      if (waitingSignature.value) waitingSignatureAck.value = true;
    });

    const templateActiveDate = computed(() => {
      return utils.formatSimpleDate(props.templateData.date);
    });
    const templateDate = computed(() => {
      return utils.formatSimpleDate(props.templateData.date);
    });

    const templateValidityFormatted = computed(() => {
      if (props.templateData.expirationDate) {
        return (
          t('templateDisplay.expiresOnTxt') +
          ' ' +
          utils.formatSimpleDate(props.templateData.expirationDate)
        );
      } else if (props.templateData.expirationDuration) {
        return (
          t('templateDisplay.validDurationTxt') +
          ' ' +
          Math.round(
            props.templateData.expirationDuration / (60 * 60 * 24 * 30)
          ) +
          ' ' +
          (Math.round(
            props.templateData.expirationDuration / (60 * 60 * 24 * 30)
          ) > 1
            ? t('templateDisplay.monthsTxt')
            : t('templateDisplay.monthTxt'))
        );
      } else return t('templateDisplay.infiniteTxt');
    });

    function getBadgeBotSessionBuilders() {
      api.getSessionBuilders(props.templateHash).then((res) => {
        sessionBuilders.value = res.data;
      });
    }
    getBadgeBotSessionBuilders();

    async function showSessionBuilderApiKey() {
      await navigator.clipboard.writeText(sessionBuilders.value[0].apiKey);
      createToast(`Copy to clipboard`, {
        position: 'bottom-center',
        hideProgressBar: true,
        timeout: 3000,
        transition: 'slide',
        toastBackgroundColor: '#050931',
      });
    }

    function getBadgeBotSessions(initQrCode) {
      api.getSessionsForTemplate(props.templateHash).then((res) => {
        delegatedSessions.value = res.data;

        if (delegatedSessions.value.length > 0 && initQrCode) {
          openSession.value = delegatedSessions.value.filter(
            (session) => session.active && session.didLimit != 1
          )[0];
         
        
      const openSessionList = delegatedSessions.value.filter(
        (session) => session.active && session.didLimit != 1
      );
      if (openSessionList.length > 0)
        qrCodeLink.value =
          openSessionList[openSessionList.length - 1].dynamicLink;
      } });
    }
    getBadgeBotSessions(true);

    async function copyToClipboard() {
      await navigator.clipboard.writeText(qrCodeLink.value);
      createToast(`Copy to clipboard`, {
        position: 'bottom-center',
        hideProgressBar: true,
        timeout: 3000,
        transition: 'slide',
        toastBackgroundColor: '#050931',
      });
    }

    async function downloadQRCode() {
      const canvas = document.querySelector('canvas');
      const image = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');

      let downloadLink = document.createElement('a');
      downloadLink.href = image;
      downloadLink.download = 'QRCode.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }

    function triggerQrCode() {
      const activeSessions = delegatedSessions.value.filter(
        (session) => session.active && session.didLimit != 1
      );
      if (activeSessions.length > 0) {
        displayQrCode.value = true;
        return;
      }

      if (activeSessions.length == 0 && props.templateData.name) {
        generateOpenLink();
      }
    }

    async function triggerDelete() {
      if (window._paq)
        window._paq.push([
          'trackEvent',
          `Template ${props.type}`,
          'Delete',
          'Confirm',
        ]);

      waitingSignature.value = 'signDelete';

      const methodName = 'updateIssuerTemplate';
      const methodArgs = [
        {
          type: 'bytes32',
          value:
            '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF',
        },
        {
          type: 'bytes32',
          value:
            '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF',
        },
        {
          type: 'uint256',
          value: Number(props.index),
        },
        {
          type: 'uint8',
          value: templateCategory.value,
        },
        {
          type: 'address',
          value: currentAddress.value,
        },
      ];

      const message = await utils.broadcast.broadcastEncode(
        currentAddress.value,
        methodName,
        ...methodArgs
      );

      let signature = null;
      let signatures = null;
      try {
        signatures = await signatureHandlerRef.value.sign(
          'MultiSign',
          'signDelete' + props.type,
          [
            {
              type: 'personal_sign',
              message,
            },
          ]
        );
      } catch (e) {
        console.log(e);
        waitingSignature.value = null;
        return;
      }
      [signature] = signatures;

      waitingSignature.value = null;
      waitingTransaction.value = true;

      // call broadcaster service
      utils.broadcast
        .sendTransaction(
          currentAddress.value,
          methodName + 'Signed',
          methodArgs.map((item) => item.value),
          signature
        )
        .then((broadcastResult) => {
          if (broadcastResult.status == 'success') {
            waitingTransaction.value = false;
            deleteSuccessful.value = true;
            api.revokeAllSessionsForTemplate(props.templateHash).then((res) => {
              console.log(res.data);
            });
            if (window._paq)
              window._paq.push([
                'trackEvent',
                `Template ${props.type}`,
                'Delete',
                'Success',
              ]);
            context.emit('delete');
          } else if (broadcastResult.status == 'fail') {
            waitingTransaction.value = null;
            showToastMessage(t('other.transactionError'));
            if (window._paq)
              window._paq.push([
                'trackEvent',
                `Template ${props.type}`,
                'Delete',
                'Fail',
              ]);
          }
        });
    }

    async function generateSessionBuilder() {
      const delegationExpirationDate =
        new Date(new Date().getTime() + 10 * 365 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 19) + 'Z';

      const badgeWithoutProof = utils.badge.createDelegationBadge(
        uuidv4(),
        currentIssuerDid.value + '#SERV_1',
        process.env.VUE_APP_BADGE_BOT_ISSUER_DID,
        props.templateData.name.replace(/\0/g, ''),
        props.templateHash,
        delegationExpirationDate
      );

      waitingSignature.value = 'signDelegation';

      // Sign verifiable credential
      const typedData = await utils.sign.getVCTypedDataV4(badgeWithoutProof);

      let signature = null;
      let signatures = null;
      try {
        signatures = await signatureHandlerRef.value.sign(
          'MultiSign',
          'signDelegation' + props.type,
          [
            {
              type: 'eth_signTypedData',
              message: typedData,
            },
          ]
        );
      } catch (e) {
        console.log(e);
        waitingSignature.value = null;
        return;
      }
      [signature] = signatures;

      waitingSignature.value = null;
      waitingLinkCreation.value = true;

      const delegationBadge = await utils.sign.addProofToVerifiableCredential(
        badgeWithoutProof,
        currentIssuerDid.value,
        signature
      );

      // Verifying signature
      const address = await utils.sign.recoverVCTypedSignatureV4(
        delegationBadge
      );

      if (address != currentAddress.value) {
        throw new Error('Signature invalid for verifiable credential');
      }

      // send to badge bot
      api
        .createSessionBuilder({
          delegationBadge,
        })
        .then((res) => {
          console.log(res.data);
          waitingLinkCreation.value = false;
          getBadgeBotSessionBuilders();
        });
    }

    async function generateOpenLink() {
      let delegationExpirationDate =
        new Date(new Date().getTime() + 10 * 365 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 19) + 'Z';
      if (props.type == 'Participation') {
        delegationExpirationDate =
          new Date(props.templateData.eventDetails.endDate)
            .toISOString()
            .slice(0, 19) + 'Z';
      }

      const badgeWithoutProof = utils.badge.createDelegationBadge(
        uuidv4(),
        currentIssuerDid.value + '#SERV_1',
        process.env.VUE_APP_BADGE_BOT_ISSUER_DID,
        props.templateData.name.replace(/\0/g, ''),
        props.templateHash,
        delegationExpirationDate
      );

      waitingSignature.value = 'signDelegation';

      // Sign verifiable credential
      const typedData = await utils.sign.getVCTypedDataV4(badgeWithoutProof);

      let signature = null;
      let signatures = null;
      try {
        signatures = await signatureHandlerRef.value.sign(
          'MultiSign',
          'signDelegation' + props.type,
          [
            {
              type: 'eth_signTypedData',
              message: typedData,
            },
          ]
        );
      } catch (e) {
        console.log(e);
        waitingSignature.value = null;
        return;
      }
      [signature] = signatures;

      waitingSignature.value = null;
      waitingLinkCreation.value = true;

      const delegationBadge = await utils.sign.addProofToVerifiableCredential(
        badgeWithoutProof,
        currentIssuerDid.value,
        signature
      );

      // Verifying signature
      const address = await utils.sign.recoverVCTypedSignatureV4(
        delegationBadge
      );

      if (address != currentAddress.value) {
        throw new Error('Signature invalid for verifiable credential');
      }

      // send to badge bot
      api
        .createSession({
          verifiableCredentials: [],
          delegationBadge,
          ...(props.templateData.eventDetails && {
            startDate: props.templateData.eventDetails.startDate,
          }),
          ...(props.templateData.eventDetails &&
            props.templateData.eventDetails.limit != -1 && {
              didLimit: props.templateData.eventDetails.limit,
            }),
        })
        .then((res) => {
          qrCodeLink.value = res.data.links[0];
          waitingLinkCreation.value = false;
          displayQrCode.value = true;
          getBadgeBotSessions();
        });
    }

    async function generateLimitedOpenLink() {
      displayDateSelector.value = false;
      const delegationExpirationDate =
        new Date(dateRange.value[1]).toISOString().slice(0, 19) + 'Z';

      const badgeWithoutProof = utils.badge.createDelegationBadge(
        uuidv4(),
        currentIssuerDid.value + '#SERV_1',
        process.env.VUE_APP_BADGE_BOT_ISSUER_DID,
        props.templateData.name.replace(/\0/g, ''),
        props.templateHash,
        delegationExpirationDate
      );

      waitingSignature.value = 'signDelegation';

      // Sign verifiable credential
      const typedData = await utils.sign.getVCTypedDataV4(badgeWithoutProof);

      let signature = null;
      let signatures = null;
      try {
        signatures = await signatureHandlerRef.value.sign(
          'MultiSign',
          'signDelegation' + props.type,
          [
            {
              type: 'eth_signTypedData',
              message: typedData,
            },
          ]
        );
      } catch (e) {
        console.log(e);
        waitingSignature.value = null;
        return;
      }
      [signature] = signatures;

      waitingSignature.value = null;
      waitingLinkCreation.value = true;

      const delegationBadge = await utils.sign.addProofToVerifiableCredential(
        badgeWithoutProof,
        currentIssuerDid.value,
        signature
      );

      // Verifying signature
      const address = await utils.sign.recoverVCTypedSignatureV4(
        delegationBadge
      );

      if (address != currentAddress.value) {
        throw new Error('Signature invalid for verifiable credential');
      }

      // send to badge bot
      api
        .createSession({
          verifiableCredentials: [],
          delegationBadge,
          startDate: dateRange.value[0],
        })
        .then((res) => {
          qrCodeLink.value = res.data.links[0];
          waitingLinkCreation.value = false;
          displayQrCode.value = true;
          getBadgeBotSessions();
        });
    }

    async function generateCustomLink() {
      // expiration date is +30 days from now
      let delegationExpirationDate =
        new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 19) + 'Z';
      if (
        props.type == 'Participation' &&
        new Date(delegationExpirationDate) <
          new Date(props.templateData.eventDetails.endDate)
      ) {
        delegationExpirationDate =
          new Date(props.templateData.eventDetails.endDate)
            .toISOString()
            .slice(0, 19) + 'Z';
      }

      const badgeWithoutProof = utils.badge.createDelegationBadge(
        uuidv4(),
        currentIssuerDid.value + '#SERV_1',
        process.env.VUE_APP_BADGE_BOT_ISSUER_DID,
        props.templateData.name.replace(/\0/g, ''),
        props.templateHash,
        delegationExpirationDate
      );

      waitingSignature.value = 'signDelegation';

      // Sign verifiable credential
      const typedData = await utils.sign.getVCTypedDataV4(badgeWithoutProof);
      console.log('typedData', typedData);

      let signature = null;
      let signatures = null;
      try {
        signatures = await signatureHandlerRef.value.sign(
          'MultiSign',
          'signDelegation' + props.type,
          [
            {
              type: 'eth_signTypedData',
              message: typedData,
            },
          ]
        );
      } catch (e) {
        console.log(e);
        waitingSignature.value = null;
        return;
      }
      [signature] = signatures;

      waitingSignature.value = null;
      waitingLinkCreation.value = true;

      const delegationBadge = await utils.sign.addProofToVerifiableCredential(
        badgeWithoutProof,
        currentIssuerDid.value,
        signature
      );

      // Verifying signature
      const address = await utils.sign.recoverVCTypedSignatureV4(
        delegationBadge
      );

      if (address != currentAddress.value) {
        throw new Error('Signature invalid for verifiable credential');
      }

      // send to badge bot
      api
        .createSession({
          verifiableCredentials: [],
          delegationBadge,
          didLimit: 1,
          ...(props.templateData.eventDetails && {
            startDate: props.templateData.eventDetails.startDate,
          }),
        })
        .then((res) => {
          qrCodeLink.value = res.data.links[0];
          waitingLinkCreation.value = false;
          displayQrCode.value = true;
          getBadgeBotSessions();
        });
    }

    async function revokeDelegations() {
      // send to badge bot
      api.revokeAllSessionsForTemplate(props.templateHash).then(() => {
        getBadgeBotSessions();
      });
    }

    function showToastMessage(text) {
      createToast(text, {
        position: 'bottom-center',
        hideProgressBar: true,
        timeout: 3000,
        transition: 'slide',
        toastBackgroundColor: '#ff4545',
      });
    }

    function sendBadge() {
      context.emit('send');
    }

    function triggerCloseDisplay() {
      context.emit('close');
    }

    function triggerDeleteEvent() {
      context.emit('delete');
    }

    function triggerEdit() {
      context.emit('edit');
    }

    function generateVerification() {
      displayQrCodeVerif.value = true;
      showMore.value = false;
      const data = {
        type: 'ValidatorData',
        created: new Date().toISOString(),
        templateHash: props.templateHash,
        issuerDid: props.templateData.issuerDID,
        messageDataEip712Schema:
          props.templateData.badgeCategory == 'Role'
            ? 'https://myntfsid.mypinata.cloud/ipfs/QmR7g3nVV1e1HsJkPP56fQZTguq9L4Q6EXX4gWVpPDX1o3'
            : 'https://myntfsid.mypinata.cloud/ipfs/Qmb1TiAsamCv8eZ7tZNUYz96Vqyj2u7bLTC1uynatd2xxu',
        delegationIssuerDid: process.env.VUE_APP_BADGE_BOT_ISSUER_DID,
        delegationMessageDataEip712Schema:
          'https://myntfsid.mypinata.cloud/ipfs/QmZgMKBF3Fp9rokyo7qsEbN6stRWZc4R11oiTT7dX9BxAd',
      };
      console.log(JSON.stringify(data, null, 2));
      qrCodeVerifValue.value = JSON.stringify(data);
    }

    return {
      currentIssuerInfo,
      triggerCloseDisplay,
      sendBadge,
      signatureHandlerRef,
      waitingSignature,
      waitingLinkCreation,
      waitingTransaction,
      waitingSignatureAck,
      deleteSuccessful,
      generateCustomLink,
      generateOpenLink,
      generateLimitedOpenLink,
      generateSessionBuilder,
      displayQrCode,
      displayQrCodeVerif,
      generateVerification,
      qrCodeLink,
      qrCodeVerifValue,
      showMore,
      revokeDelegations,
      triggerDelete,
      triggerDeleteEvent,
      triggerQrCode,
      triggerEdit,
      templateActiveDate,
      templateDate,
      delegatedSessions,
      sessionBuilders,
      openSession,
      templateValidityFormatted,
      templateLabel,
      copyToClipboard,
      downloadQRCode,
      isMydidIssuer,
      showSessionBuilderApiKey,
      displayDateSelector,
      dateRange,
    };
  },
  components: {
    TemplatePreview,
    TemplateRolePreview,
    SignatureHandler,
    BasicSmModal,
    TemplateLabel,
  },
};
</script>
