<template>
  <div class="flex flex-col h-full py-10 px-16">
    <p class="font-bold text-xl mb-5">
      {{ $t('settings.termsOfService.title') }}
    </p>
    <iframe
      :src="`https://balota.fr/legal/terms-${
        locale == 'en' ? 'us' : locale
      }.html`"
      class="grow w-full"
    ></iframe>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { locale } = useI18n();

    return { locale };
  },
};
</script>
