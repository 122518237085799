<template>
  <div class="h-full flex flex-col overflow-auto" ref="scrollContainer">
    <TourGuide ref="tourGuideRef" @finish="cancelTour()"></TourGuide>
    <div class="pt-10 pb-7">
      <router-link to="/">
        <div class="flex justify-center items-center">
          <div class="flex">
            <img
              :src="require('/assets/images/logo.png')"
              alt=""
              class="w-5 self-center mr-3"
            />
            <p class="font-bold text-xl">
              {{ $t('navigation.communityStudio') }}
            </p>
          </div>
        </div></router-link
      >
    </div>
    <div v-if="!isSetting">
      <div class="p-3 pb-1">
        <div
          id="tg-navBar-search"
          class="flex hover:bg-white items-center border border-gray-300 shadow-sm rounded-lg px-3 py-2 gap-3 cursor-pointer"
          @click="router.push('/search')"
        >
          <div class="flex-none w-5 h-5 relative">
            <div
              class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            >
              <icon name="magnifying-glass" />
            </div>
          </div>
          <p class="flex-none font-medium">
            {{ $t('navigation.searchButton') }}
          </p>
        </div>
      </div>
      <div class="mt-5 p-3">
        <p class="text-sm text-gray-600 mx-4 mb-2">
          {{ $t('navigation.badgesSection') }}
        </p>
        <NavButton
          id="tg-navBar-basic"
          :label="templateTypeToLabel('Basic', i18n)"
          :showAddButton="true"
          :showSendButton="true"
          @clickNavButton="router.push('/templates/basic')"
          @clickSendButton="router.push('/templates/basic?send=1')"
          @clickAddButton="router.push('/templates/basic?add=1')"
          ><icon name="badge-basic"
        /></NavButton>
        <NavButton
          id="tg-navBar-community"
          :label="templateTypeToLabel('Community', i18n)"
          :showAddButton="true"
          @clickNavButton="router.push('/templates/community')"
          @clickAddButton="router.push('/templates/community?add=1')"
          ><icon name="badge-community"
        /></NavButton>
      </div>
      <div class="p-3">
        <p class="text-sm text-gray-600 mx-4 mb-2">
          {{ $t('navigation.eventsSection') }}
        </p>
        <NavButton
          id="tg-navBar-participation"
          :label="templateTypeToLabel('Participation', i18n)"
          :showAddButton="true"
          :showSendButton="true"
          @clickNavButton="router.push('/templates/participation')"
          @clickSendButton="router.push('/templates/participation?send=1')"
          @clickAddButton="router.push('/templates/participation?add=1')"
          ><icon name="badge-participation"
        /></NavButton>
      </div>
      <div class="p-3">
        <p class="text-sm text-gray-600 mx-4 mb-2">
          {{ $t('navigation.roleAndAccessSection') }}
        </p>
        <NavButton
          id="tg-navBar-membership"
          :label="templateTypeToLabel('Membership', i18n)"
          :showAddButton="false"
          :showSendButton="
            !membershipTemplateListLoading &&
            membershipTemplateList &&
            membershipTemplateList.length > 0
          "
          @clickNavButton="router.push('/templates/membership')"
          @clickSendButton="router.push('/templates/membership?send=1')"
          @clickAddButton="router.push('/templates/membership?add=1')"
          ><icon name="badge-membership"
        /></NavButton>
        <NavButton
          id="tg-navBar-role"
          :label="templateTypeToLabel('Role', i18n)"
          :showAddButton="true"
          :showSendButton="true"
          @clickNavButton="router.push('/templates/role')"
          @clickSendButton="router.push('/templates/role?send=1')"
          @clickAddButton="router.push('/templates/role?add=1')"
          ><icon name="badge-role"
        /></NavButton>
      </div>
    </div>
    <div v-else>
      <div class="p-3">
        <NavButton
          class="py-5 mb-1.5"
          :label="$t('navigation.settingsButton')"
          @clickNavButton="router.push('/')"
          ><icon name="chevron-left"
        /></NavButton>
        <NavButton
          class="mb-1.5"
          :label="$t('navigation.profileInformationButton')"
          @clickNavButton="router.push('/settings/profile')"
          ><icon name="user-square" class="-ml-1"
        /></NavButton>
        <NavButton
          class="mb-1.5"
          :label="$t('navigation.languageButton')"
          @clickNavButton="router.push('/settings/language')"
          ><icon name="globe"
        /></NavButton>
        <NavButton
          class="mb-1.5"
          :label="$t('navigation.termsButton')"
          @clickNavButton="router.push('/settings/terms')"
          ><icon name="notebook"
        /></NavButton>
        <NavButton
          class="mb-1.5"
          :label="$t('navigation.privacyButton')"
          @clickNavButton="router.push('/settings/privacy')"
          ><icon name="notebook"
        /></NavButton>
        <NavButton
          class="mb-1.5"
          :label="$t('navigation.legalButton')"
          @clickNavButton="router.push('/settings/legal')"
          ><icon name="notebook"
        /></NavButton>
        <NavButton
          class="mb-1.5"
          :label="$t('navigation.cookieButton')"
          @clickNavButton="router.push('/settings/cookies')"
          ><icon name="notebook"
        /></NavButton>
      </div>
    </div>
    <div class="flex-grow"></div>
    <div v-if="!isSetting" class="p-3">
      <div class="min-h-full">
        <div
          id="tg-navBar-menu"
          class="w-full bottom-0 rounded-xl border p-1"
          :class="extendMenu ? 'bg-white' : 'border-transparent'"
          @mouseenter="(extendMenu = true) && scrollToBottom()"
          @mouseleave="extendMenu = false"
        >
          <div v-if="extendMenu" class="mb-2">
            <p
              @click="disconnectIssuer()"
              class="font-medium hover:bg-gray-light text-blue-800 rounded-lg cursor-pointer px-4 py-2 mt-0.5 mb-1.5"
            >
              <icon
                name="log-out"
                class="stroke-blue-800 fill-transparent mr-2"
              />{{ $t('navigation.logoutButton') }}
            </p>
            <p
              @click="showMultiCommunityModal = true"
              class="font-medium hover:bg-gray-light rounded-lg cursor-pointer px-4 py-2 mt-0.5 mb-1.5"
            >
              <icon
                name="layers"
                class="stroke-transparent fill-black mr-2"
              />{{ $t('navigation.newCommunityButton') }}
            </p>
            <router-link to="/settings/profile"
              ><p
                @click="extendMenu = false"
                class="font-medium hover:bg-gray-light rounded-lg cursor-pointer px-4 py-2 mb-1.5"
              >
                <icon name="settings" class="mr-2" />{{
                  $t('navigation.settingsButton')
                }}
              </p></router-link
            >

            <a href="https://support.balota.fr/" target="_blank">
              <p
                class="font-medium hover:bg-gray-light rounded-lg cursor-pointer px-4 py-2 mb-1.5"
              >
                <icon name="info" class="mr-2" />{{
                  $t('navigation.helpButton')
                }}
              </p></a
            >
          </div>
          <div v-if="currentIssuerInfo" class="flex items-center px-4 py-3">
            <img
              :src="currentIssuerInfo.profile.profileImage"
              alt=""
              class="flex-none border bg-gray-200 w-10 h-10 my-auto rounded-full mr-3"
            />
            <p class="w-full font-bold break-words line-clamp-1">
              {{ currentIssuerInfo.profile.name }}
            </p>
            <div class="grow"></div>
            <p v-if="!extendMenu" class="text-gray-400 text-xl">
              <icon name="unfold-more" class="w-7" />
            </p>
            <p
              v-else
              @click="showMultiCommunityModal = true"
              class="text-gray-400 text-xl cursor-pointer"
            >
              <icon name="plus" class="w-7" />
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        class="flex justify-center border-t px-3 py-7 cursor-pointer"
        @click="showDeleteConfirmation = true"
      >
        <p class="text-lg font-bold mr-1">
          <icon name="trash-empty" class="mr-2" />{{
            $t('navigation.deleteAccountButton')
          }}
        </p>
      </div>
    </div>
    <div v-show="showDeleteConfirmation">
      <BasicSmModal @close="showDeleteConfirmation = false">
        <p class="text-center text-lg font-bold mb-8 px-5">
          {{ $t('navigation.deleteTitle') }}
        </p>
        <p class="text-center text-gray-500 mb-4 px-7">
          {{ $t('navigation.deleteTxt') }}
        </p>
        <p class="text-center text-gray-500 mb-5 px-7">
          {{ $t('navigation.typeDeleteTxt') }}
        </p>
        <div class="w-full flex justify-center mb-8">
          <input
            type="text"
            :placeholder="$t('navigation.typeDeletePlaceholder')"
            class="w-52 outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-2 mx-auto"
            v-model="deleteInput"
          />
        </div>
        <div class="flex justify-center gap-4">
          <button
            @click="showDeleteConfirmation = false"
            class="text-sm bg-white border shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2.5"
          >
            {{ $t('navigation.deleteCancelButton') }}
          </button>
          <button
            @click="deleteIssuer() && (showDeleteConfirmation = false)"
            class="text-sm bg-red-500 text-white shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2.5"
            :class="{
              'pointer-events-none opacity-50': !deleteInputValid,
            }"
          >
            {{ $t('navigation.deleteConfirmButton') }}
          </button>
        </div>
      </BasicSmModal>
    </div>
    <div v-if="waitingSignature">
      <div class="fixed top-0 left-0 w-full h-full z-10">
        <div class="absolute w-full h-full bg-black bg-opacity-20"></div>
      </div>
      <SignatureHandler
        @ack="waitingSignatureAck = false"
        @close="waitingSignature = null"
        ref="signatureHandlerRef"
      >
        <div v-if="!waitingSignatureAck" class="mt-5 mb-10">
          <div class="justify-center mb-5">
            <Vue3Lottie
              :animationLink="require('/assets/images/loader-app.jpg')"
              :height="160"
              :width="160"
              :speed="0.7"
            />
          </div>
          <p class="text-center text-xl font-bold px-20 mb-1">
            {{ $t('navigation.confirmAccountDeleteTxt') }}
          </p>
          <p class="text-center px-10 mb-4">
            {{ $t('templateCreation.onMydidAppTxt') }}
          </p>
        </div>
        <div v-else class="mt-5 mb-10">
          <div class="justify-center mb-5">
            <Vue3Lottie
              :animationLink="require('/assets/images/loader-wait-app.jpg')"
              :height="100"
              :width="100"
              :speed="1"
            />
          </div>
          <p class="text-center text-xl font-bold px-20 mb-4">
            {{ $t('other.waitingAppConnection') }}
          </p>
        </div></SignatureHandler
      >
    </div>
    <div v-else-if="waitingTransaction">
      <div class="fixed top-0 left-0 w-full h-full z-10">
        <div class="absolute w-full h-full bg-black bg-opacity-20"></div>
      </div>
      <BasicSmModal :noCloseOption="true">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-round.jpg')"
            :height="50"
            :width="50"
            :speed="1.5"
          />
        </div>
        <p class="text-center text-xl font-bold px-10">
          {{ $t('other.operationInProgressTxt') }}
        </p>
      </BasicSmModal>
    </div>
    <div v-else-if="transactionSuccessful">
      <div class="fixed top-0 left-0 w-full h-full z-10">
        <div class="absolute w-full h-full bg-black bg-opacity-20"></div>
      </div>
      <BasicSmModal @close="transactionSuccessful = false">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-check.jpg')"
            :height="160"
            :width="160"
            :speed="0.5"
            :loop="false"
          />
        </div>
        <p class="text-center text-xl font-bold px-20 mb-5">
          {{ $t('navigation.deleteSuccessfulTxt') }}
        </p>
      </BasicSmModal>
    </div>
    <div v-else-if="showMultiCommunityModal">
      <div class="fixed top-0 left-0 w-full h-full z-10">
        <div class="absolute w-full h-full bg-black bg-opacity-20"></div>
      </div>
      <BasicSmModal
        @close="showMultiCommunityModal = false"
        :large="true"
        :absoluteClose="true"
      >
        <p class="text-lg text-gray-500">
          {{ $t('navigation.multiCommunity.title') }}
        </p>
        <div class="text-center max-w-[28rem] mx-auto my-5 pt-5">
          <p class="text-lg font-medium">
            {{ $t('navigation.multiCommunity.titleBis') }}
          </p>
          <p class="text-center">
            {{ $t('navigation.multiCommunity.descriptionTxt') }}
          </p>
          <div class="justify-center my-5">
            <Vue3Lottie
              :animationLink="require('/assets/images/loader-buffer.jpg')"
              :height="160"
              :width="160"
              :speed="1"
              :loop="false"
            />
          </div>
          <p class="text-center mb-7">
            {{ $t('navigation.multiCommunity.descriptionTxtBis') }}
          </p>
          <button
            @click="disconnectIssuer(true)"
            class="text-sm bg-secondary text-white shadow-sm font-medium rounded-lg cursor-pointer py-2 px-20"
          >
            {{ $t('navigation.multiCommunity.button') }}
          </button>
        </div>
      </BasicSmModal>
    </div>
    <div v-else-if="showTourModal">
      <div class="fixed top-0 left-0 w-full h-full z-10">
        <div class="absolute w-full h-full bg-black bg-opacity-20"></div>
      </div>
      <BasicSmModal @close="cancelTour">
        <div class="text-center max-w-[28rem] mx-auto pt-5">
          <p class="text-lg font-medium mb-4">
            {{ $t('tourGuide.welcomeTitle') }}
          </p>
          <p class="text-center">
            {{ $t('tourGuide.welcomeTxt') }}
          </p>
          <div class="justify-center mt-5">
            <Vue3Lottie
              :animationLink="require('/assets/images/loader-dashboard.jpg')"
              :height="160"
              :width="160"
              :speed="1"
              :loop="false"
            />
          </div>
          <div class="flex mt-16">
            <button
              @click="cancelTour"
              class="text-sm w-80 text-gray-500 rounded-lg cursor-pointer py-2"
            >
              {{ $t('tourGuide.skipTour') }}
            </button>
            <div class="w-full"></div>
            <button
              @click="startTour"
              class="text-sm w-80 bg-secondary text-white shadow-sm font-medium rounded-lg cursor-pointer py-2"
            >
              {{ $t('tourGuide.takeTourButton') }}
            </button>
          </div>
        </div>
      </BasicSmModal>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { createToast } from 'mosha-vue-toastify';
import '@sjmc11/tourguidejs/src/scss/tour.scss';
import { TourGuideClient } from '@sjmc11/tourguidejs';

import NavButton from './nav/NavButton.vue';
import BasicSmModal from './BasicSmModal.vue';
import SignatureHandler from './SignatureHandler.vue';
import TourGuide from './TourGuide.vue';
export default {
  setup() {
    const utils = inject('utils');
    const api = inject('api');
    const nostr = inject('nostr');
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const cookies = inject('cookies');

    const isAdmin = ref(false);
    const seeMore = ref(false);

    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);
    const currentIssuerInfo = computed(
      () => store.getters.getCurrentIssuerInfo
    );
    const currentAddress = computed(() => store.getters.getCurrentAddress);

    const membershipTemplateList = computed(
      () => store.getters.getMembershipTemplateList
    );
    const membershipTemplateListLoading = computed(
      () => store.getters.getMembershipTemplateListLoading
    );

    const showDeleteConfirmation = ref(false);
    const templateTypeToLabel = ref(utils.templateTypeToLabel);
    const i18n = ref(useI18n());
    const deleteInput = ref(null);
    const deleteInputValid = computed(
      () => deleteInput.value == 'delete' || deleteInput.value == 'supprimer'
    );

    const extendMenu = ref(false);
    const showMore = ref(false);
    const scrollContainer = ref(null);
    const showMultiCommunityModal = ref(false);
    const showTourModal = ref(false);
    const tourGuideRef = ref();

    const signatureHandlerRef = ref();
    const waitingSignature = ref(null);
    const waitingSignatureAck = ref(false);
    const waitingTransaction = ref(false);
    const transactionSuccessful = ref(false);

    const isSetting = computed(() => {
      return route.path.includes('/settings');
    });

    if (currentIssuerDid.value) {
      utils.mydid
        .isAdmin(currentAddress.value)
        .then((status) => (isAdmin.value = status));
    }

    onMounted(() => {
      const tourGuideNav = cookies.get('tourGuideNav');
      if (!tourGuideNav) {
        showTourModal.value = true;
        store.commit('setTourOngoing', true);
      }
    });

    function startTour() {
      showTourModal.value = false;

      tourGuideRef.value.start([
        {
          content: t('tourGuide.navTour.basicTxt'),
          title: t('tourGuide.navTour.basicTitle'),
          id: 'tg-navBar-basic',
        },
        {
          content: t('tourGuide.navTour.communityTxt'),
          title: t('tourGuide.navTour.communityTitle'),
          id: 'tg-navBar-community',
        },
        {
          content: t('tourGuide.navTour.participationTxt'),
          title: t('tourGuide.navTour.participationTitle'),
          id: 'tg-navBar-participation',
        },
        {
          content: t('tourGuide.navTour.membershipTxt'),
          title: t('tourGuide.navTour.membershipTitle'),
          id: 'tg-navBar-membership',
        },
        {
          content: t('tourGuide.navTour.roleTxt'),
          title: t('tourGuide.navTour.roleTitle'),
          id: 'tg-navBar-role',
        },
        {
          content: t('tourGuide.navTour.menuTxt'),
          title: t('tourGuide.navTour.menuTitle'),
          id: 'tg-navBar-menu',
        },
      ]);
    }

    function cancelTour() {
      showTourModal.value = false;
      cookies.set('tourGuideNav', 1, -1);
      store.commit('setTourOngoing', false);
    }

    async function disconnectIssuer(signupRedirect) {
      await nostr.disconnectSession();
      cookies.remove('token');
      cookies.remove('nostr_key');
      cookies.remove('nostr_user_key');
      if (signupRedirect) {
        cookies.set('signup', 1);
      }
      store.commit('resetIssuerState');
    }

    async function deleteIssuer() {
      waitingSignature.value = 'updateProfile';

      let methodNameSetAttribute = 'setAttribute';
      let methodArgsSetAttribute = [
        {
          type: 'address',
          value: currentAddress.value,
        },
        {
          type: 'bytes32',
          value:
            '0x' +
            (
              '0000000000000000000000000000000000000000000000000000000000000000' +
              utils.asciiToHex(`SERV,Public Profile`).replace('0x', '')
            ).slice(-64),
        },
        {
          type: 'uint',
          value: 99 * 365 * 24 * 60 * 60,
        },
        {
          type: 'string',
          value: '',
        },
      ];

      let messageSetAttribute = await utils.broadcast.broadcastEncode(
        currentAddress.value,
        methodNameSetAttribute,
        ...methodArgsSetAttribute
      );

      let methodNameChangeController = 'changeController';
      let methodArgsChangeController = [
        {
          type: 'address',
          value: currentAddress.value,
        },
        {
          type: 'address',
          value: '0xffffffffffffffffffffffffffffffffffffffff',
        },
      ];

      let messageChangeController = await utils.broadcast.broadcastEncodeNext(
        currentAddress.value,
        methodNameChangeController,
        ...methodArgsChangeController
      );

      // MULTI SIGN
      let signatures = null;
      try {
        signatures = await signatureHandlerRef.value.sign(
          'MultiSign',
          'signDeleteIssuer',
          [
            {
              type: 'personal_sign',
              message: messageSetAttribute,
            },
            {
              type: 'personal_sign',
              message: messageChangeController,
            },
          ]
        );
      } catch (e) {
        console.log(e);
        waitingSignature.value = null;
        return;
      }

      const [
        broadcastSignatureSetAttribute,
        broadcastSignatureChangeController,
      ] = signatures;

      waitingSignature.value = null;
      waitingTransaction.value = true;

      let broadcastResult = await utils.broadcast.sendTransaction(
        currentAddress.value,
        methodNameSetAttribute + 'Signed',
        methodArgsSetAttribute.map((item) => item.value),
        broadcastSignatureSetAttribute
      );

      if (broadcastResult.status == 'fail') {
        waitingTransaction.value = null;
        showToastMessage(t('other.transactionError'));
        return;
      }

      utils.broadcast
        .sendTransaction(
          currentAddress.value,
          methodNameChangeController + 'Signed',
          methodArgsChangeController.map((item) => item.value),
          broadcastSignatureChangeController
        )
        .then(async (broadcastResult) => {
          if (broadcastResult.status == 'success') {
            waitingTransaction.value = null;
            transactionSuccessful.value = true;
            await api.disableIssuer();
            await nostr.publishEvent('ProfileUpdated', {});
            setTimeout(() => {
              cookies.remove('token');
              window.location.href = '/';
            }, 3000);
          } else if (broadcastResult.status == 'fail') {
            waitingTransaction.value = null;
            showToastMessage(t('other.transactionError'));
          }
        });
    }

    function scrollToBottom() {
      setTimeout(() => {
        if (scrollContainer.value) {
          scrollContainer.value.scrollTop = scrollContainer.value.scrollHeight;
        }
      }, 50);
    }

    function showToastMessage(text) {
      createToast(text, {
        position: 'bottom-center',
        hideProgressBar: true,
        timeout: 3000,
        transition: 'slide',
        toastBackgroundColor: '#ff4545',
      });
    }

    return {
      isAdmin,
      isSetting,
      currentIssuerDid,
      currentIssuerInfo,
      seeMore,
      router,
      showDeleteConfirmation,
      templateTypeToLabel,
      i18n,
      disconnectIssuer,
      extendMenu,
      showMore,
      scrollContainer,
      scrollToBottom,
      deleteIssuer,
      deleteInput,
      deleteInputValid,
      signatureHandlerRef,
      waitingSignature,
      waitingSignatureAck,
      waitingTransaction,
      transactionSuccessful,
      showMultiCommunityModal,
      showTourModal,
      startTour,
      cancelTour,
      tourGuideRef,
      membershipTemplateList,
      membershipTemplateListLoading,
    };
  },
  components: { NavButton, BasicSmModal, SignatureHandler, TourGuide },
};
</script>
