<template>
  <div class="py-10 px-16">
    <div class="flex items-center mb-10">
      <p class="text-xl font-bold">
        {{ $t('templates.manageMembershipTemplates.title') }}
      </p>
      <div class="grow"></div>
      <button
        v-if="
          !membershipTemplateListLoading &&
          membershipTemplateList &&
          membershipTemplateList.length == 0
        "
        class="text-sm bg-white border shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2 ml-auto mr-3"
        @click="
          (showTemplateCreation = true) &&
            (currentTemplateCreateTemplateData = null)
        "
        click-outside-ignore-templatecreation
      >
        <icon name="plus" class="mr-1.5" />Create a card
      </button>
      <button
        v-if="
          !membershipTemplateListLoading &&
          membershipTemplateList &&
          membershipTemplateList.length > 0
        "
        class="text-sm bg-secondary text-white shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2"
        @click="
          () => {
            showTemplateSender = true;
            currentTemplateSenderTemplateData = membershipTemplateList[0].data;
          }
        "
        click-outside-ignore-templatesender
      >
        <icon name="mail" class="fill-white mr-1.5" />{{
          $t('templates.manageMembershipTemplates.sendButton')
        }}
      </button>
    </div>
    <div class="flex flex-wrap gap-5 mb-10">
      <div
        v-if="!membershipTemplateListLoading"
        v-for="(template, index) in membershipTemplateList"
        :key="template.index"
      >
        <TemplateCard
          v-if="template.data && currentIssuerInfo"
          :templateData="template.data"
          :templateSessions="template.sessions"
          :issuerName="currentIssuerInfo.profile.name"
          type="Membership"
          :index="Number(template.index)"
          @click="currentDisplayedTemplateIndex = index"
          class="cursor-pointer"
          click-outside-ignore-templatedisplay
        />
        <TemplateDisplay
          v-if="template.data && currentDisplayedTemplateIndex == index"
          :templateData="template.data"
          :templateSessions="template.sessions"
          :templateHash="template.templateHash"
          type="Membership"
          @close="currentDisplayedTemplateIndex = -1"
          @delete="
            () => {
              currentDisplayedTemplateIndex = -1;
              refreshTemplates();
            }
          "
          @send="
            () => {
              currentDisplayedTemplateIndex = -1;
              showTemplateSender = true;
              currentTemplateSenderTemplateData = template.data;
            }
          "
          @edit="
            () => {
              currentDisplayedTemplateIndex = -1;
              currentTemplateCreateTemplateData = template;
              showTemplateCreation = true;
            }
          "
          click-outside-ignore-templatedisplay-target
        />
      </div>
      <div v-else class="w-full flex justify-center pt-10">
        <Vue3Lottie
          :animationLink="require('/assets/images/loader-round.jpg')"
          :height="100"
          :width="100"
          :speed="1.5"
        />
      </div>
      <!-- <div
        v-if="
          !membershipTemplateListLoading &&
          membershipTemplateList &&
          membershipTemplateList.length == 0
        "
        class="mt-20 mx-auto"
      >
        <TemplateAddSection
          type="Membership"
          @create="
            (showTemplateCreation = true) &&
              (currentTemplateCreateTemplateData = null)
          "
        ></TemplateAddSection>
      </div> -->
    </div>
    <div v-if="showOnboarding">
      <div class="fixed top-0 left-0 w-full h-full z-10">
        <div class="absolute w-full h-full bg-black bg-opacity-20"></div>
      </div>
      <BasicSmModal :noCloseOption="true">
        <p class="text-center text-2xl font-bold mb-4">
          {{ $t('templates.manageMembershipTemplates.onboardingTitle')
          }}<span class="text-3xl ml-2">🙌</span>
        </p>
        <p class="text-center text-gray-500 mb-4 px-10">
          {{ $t('templates.manageMembershipTemplates.onboardingText') }}
        </p>
        <div class="-mx-10 mb-8">
          <img
            :src="require('/assets/images/onboarding_cards.png')"
            alt=""
            class="w-full"
          />
        </div>
        <div class="flex justify-center mb-6">
          <button
            @click="handleOnboardingClick"
            class="bg-secondary text-white shadow-sm rounded-lg cursor-pointer py-1.5 px-2.5"
          >
            {{ $t('templates.manageMembershipTemplates.onboardingButton') }}
          </button>
        </div>
        <p class="text-center text-gray-500 mb-4 px-10">
          {{ $t('templates.manageMembershipTemplates.onboardingTextBis') }}
        </p>
      </BasicSmModal>
    </div>
    <TemplateCreate
      v-if="showTemplateCreation"
      type="Membership"
      :noCloseOption="
        !membershipTemplateListLoading &&
        membershipTemplateList &&
        membershipTemplateList.length == 0
      "
      @close="
        (hash) => {
          showTemplateCreation = false;
          currentTemplateSenderTemplateData = null;
          handleTemplateHashFromClose(hash);
        }
      "
      @refresh="
        () => {
          refreshTemplates();
          currentTemplateSenderTemplateData = null;
        }
      "
      :templateData="currentTemplateCreateTemplateData"
      click-outside-ignore-templatecreation-target
    />
    <TemplateSend
      v-if="showTemplateSender"
      type="Membership"
      @close="
        () => {
          showTemplateSender = false;
          currentTemplateSenderTemplateData = null;
        }
      "
      @reset="
        () => {
          currentTemplateSenderTemplateData = null;
        }
      "
      :templateList="membershipTemplateList"
      :templateSelected="currentTemplateSenderTemplateData"
      click-outside-ignore-templatesender-target
    />
  </div>
</template>

<script>
import { ref, computed, watch, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import TemplateDisplay from '../../components/templates/TemplateDisplay.vue';
import TemplateCard from '../../components/templates/TemplateCard.vue';
import TemplateCreate from '../../components/templates/TemplateCreate.vue';
import TemplateSend from '../../components/templates/TemplateSend.vue';
import TemplateAddSection from '../../components/templates/TemplateAddSection.vue';
import BasicSmModal from '../../components/BasicSmModal.vue';
export default {
  setup() {
    const utils = inject('utils');
    const store = useStore();
    const route = useRoute();
    const currentIssuerInfo = computed(
      () => store.getters.getCurrentIssuerInfo
    );
    const membershipTemplateList = computed(
      () => store.getters.getMembershipTemplateList
    );
    const membershipTemplateListLoading = computed(
      () => store.getters.getMembershipTemplateListLoading
    );
    const isTourOngoing = computed(() => store.getters.isTourOngoing);

    const showOnboarding = ref(false);

    const currentDisplayedTemplateIndex = ref(-1);
    const showTemplateCreation = ref(false);
    const showTemplateSender = ref(false);
    const currentTemplateSenderTemplateData = ref(null);
    const currentTemplateCreateTemplateData = ref(null);
    const forcedDisplayTemplateHash = ref(null);

    function handleRouteQuery() {
      if (route.query.add == '1') {
        showTemplateSender.value = false;
        showTemplateCreation.value = true;
      }
      if (route.query.send == '1') {
        if (
          !membershipTemplateListLoading.value &&
          membershipTemplateList.value &&
          membershipTemplateList.value.length > 0
        ) {
          currentTemplateSenderTemplateData.value =
            membershipTemplateList.value[0].data;
          showTemplateCreation.value = false;
          showTemplateSender.value = true;
        }
      }
      if (route.query.hash) {
        if (!membershipTemplateListLoading.value) {
          currentDisplayedTemplateIndex.value =
            membershipTemplateList.value.findIndex(
              (template) =>
                template.templateHash.replace('0x', '') == route.query.hash
            );
        } else {
          forcedDisplayTemplateHash.value = route.query.hash;
        }
      }
    }
    handleRouteQuery();
    watch(route, handleRouteQuery);

    watch([membershipTemplateListLoading, membershipTemplateList], () => {
      if (
        !membershipTemplateListLoading.value &&
        membershipTemplateList.value.length > 0 &&
        forcedDisplayTemplateHash.value
      ) {
        currentDisplayedTemplateIndex.value =
          membershipTemplateList.value.findIndex(
            (template) =>
              template.templateHash.replace('0x', '') ==
              forcedDisplayTemplateHash.value
          );
      }
    });

    async function handleTemplateHashFromClose(hash) {
      if (!hash) return;
      if (
        !membershipTemplateListLoading.value &&
        membershipTemplateList.value.length > 0
      ) {
        currentDisplayedTemplateIndex.value =
          membershipTemplateList.value.findIndex(
            (template) => template.templateHash.replace('0x', '') == hash
          );
      } else {
        forcedDisplayTemplateHash.value = hash;
      }
    }

    async function refreshTemplates() {
      store.dispatch('getMembershipTemplateList');
    }

    function handleOnboardingClick() {
      showOnboarding.value = false;
      showTemplateCreation.value = true;
    }

    // onboarding
    function checkOnboarding() {
      if (
        !membershipTemplateListLoading.value &&
        membershipTemplateList.value &&
        membershipTemplateList.value.length == 0 &&
        !isTourOngoing.value
      ) {
        showOnboarding.value = true;
      }
    }
    checkOnboarding();
    watch([membershipTemplateListLoading, isTourOngoing], checkOnboarding);

    return {
      showTemplateCreation,
      showTemplateSender,
      currentTemplateSenderTemplateData,
      currentTemplateCreateTemplateData,
      currentIssuerInfo,
      membershipTemplateList,
      membershipTemplateListLoading,
      currentDisplayedTemplateIndex,
      refreshTemplates,
      showOnboarding,
      handleOnboardingClick,
      forcedDisplayTemplateHash,
      handleTemplateHashFromClose,
    };
  },
  components: {
    TemplateDisplay,
    TemplateCard,
    TemplateCreate,
    TemplateSend,
    TemplateAddSection,
    BasicSmModal,
  },
};
</script>
