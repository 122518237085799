import { recoverTypedSignature } from '@metamask/eth-sig-util';
import { toChecksumAddress } from 'ethereum-checksum-address';
import { getJsonDataFromUrl } from '../ipfs';
const { base58btc } = require('multiformats/bases/base58');

const eip712SchemaMap = {
  givenName:
    'https://myntfsid.mypinata.cloud/ipfs/QmXM1JReim4U736RSxHzn5rd2JJ3b8iikPkmHnCGE45KbH',
  familyName:
    'https://myntfsid.mypinata.cloud/ipfs/QmQqLWzLRwhgp8UwdaGSZfWb42my2gp4YoXHFHuGarYKFK',
  birthDate:
    'https://myntfsid.mypinata.cloud/ipfs/Qmb9YjjmeC2KHR5G1UUu3K6uTW2H3dtGd5fQELrYJjikbG',
  nationality:
    'https://myntfsid.mypinata.cloud/ipfs/QmYpYhYAbtWipDMaMYoCsSfydmkB2NRPwb5Lw46cFSTms1',
  gender:
    'https://myntfsid.mypinata.cloud/ipfs/Qmd4XZkXj9RsdZaEZavziAPM8quW72NrZNdZzkb3KyLayb',
  email:
    'https://myntfsid.mypinata.cloud/ipfs/QmfVApuBeHbvnqAk5c1FxEN1mZy15KmMbyvYyN1fR8hvXs',
  award:
    'https://myntfsid.mypinata.cloud/ipfs/QmQV6pQ4nzfA5o1jvLNwQpVgnTXF3KG9cNXmAuDDYgM5K4',
};
const obEip712Schema =
  'https://myntfsid.mypinata.cloud/ipfs/Qmb1TiAsamCv8eZ7tZNUYz96Vqyj2u7bLTC1uynatd2xxu';
const obRoleEip712Schema =
  'https://myntfsid.mypinata.cloud/ipfs/QmR7g3nVV1e1HsJkPP56fQZTguq9L4Q6EXX4gWVpPDX1o3';
const obDelegationEip712Schema =
  'https://myntfsid.mypinata.cloud/ipfs/QmZgMKBF3Fp9rokyo7qsEbN6stRWZc4R11oiTT7dX9BxAd';

export function getParamsTypedDataV4(
  message,
  primaryType,
  types,
  domainChainId,
  domainName,
  domainVerifyingContract,
  domainVersion
) {
  let typedData = {
    domain: {
      chainId: domainChainId,
      name: domainName,
      verifyingContract: domainVerifyingContract,
      version: domainVersion,
    },
    message,
    primaryType,
    types,
  };

  return typedData;
}

export function recoverTypedSignatureV4(
  message,
  primaryType,
  types,
  signature,
  domainChainId,
  domainName,
  domainVerifyingContract,
  domainVersion
) {
  const typedData = {
    domain: {
      chainId: domainChainId,
      name: domainName,
      verifyingContract: domainVerifyingContract,
      version: domainVersion,
    },
    message,
    primaryType,
    types,
  };
  return toChecksumAddress(
    recoverTypedSignature({
      data: typedData,
      signature,
      version: 'V4',
    })
  );
}

export async function getVCTypedDataV4(verifiableCredential) {
  const { id, type, image, ...keyValue } =
    verifiableCredential.credentialSubject;

  let eip712SchemaUrl = null;
  let primaryType = null;

  if (verifiableCredential.type.indexOf('EndorsementCredential') != -1) {
    if (
      verifiableCredential.credentialSubject.endorsementComment.indexOf(
        'DELEGATION'
      ) == 0
    )
      eip712SchemaUrl = obDelegationEip712Schema;
    else eip712SchemaUrl = obRoleEip712Schema;
    primaryType = 'EndorsementCredential';
  } else if (verifiableCredential.type.indexOf('OpenBadgeCredential') != -1) {
    eip712SchemaUrl = obEip712Schema;
    primaryType = 'OpenBadgeCredential';
  } else {
    eip712SchemaUrl = eip712SchemaMap[Object.keys(keyValue)[0]];
    primaryType = 'VerifiableCredential';
  }

  const eip712Schema = await getJsonDataFromUrl(eip712SchemaUrl);

  const typedData = {
    domain: {
      name: 'myDid',
      chainId: parseInt(process.env.VUE_APP_CHAIN_ID),
      version: '2',
    },
    message: verifiableCredential,
    primaryType,
    types: eip712Schema,
  };

  return typedData;
}

export async function recoverVCTypedSignatureV4(verifiableCredential) {
  const { proof, ...vcWithoutProof } = verifiableCredential;
  const eip712Schema = await getJsonDataFromUrl(
    proof.eip712.messageDataEip712Schema
  );

  const typedData = {
    domain: proof.eip712.domain,
    message: vcWithoutProof,
    primaryType: proof.eip712.primaryType,
    types: eip712Schema,
  };

  return toChecksumAddress(
    recoverTypedSignature({
      data: typedData,
      signature: base58btcToHex(proof.proofValue),
      version: 'V4',
    })
  );
}

export async function addProofToVerifiableCredential(
  vcWithoutProof,
  issuerDid,
  signature
) {
  const { id, type, image, ...keyValue } = vcWithoutProof.credentialSubject;

  let eip712SchemaUrl = null;
  let primaryType = null;

  if (vcWithoutProof.type.indexOf('EndorsementCredential') != -1) {
    if (
      vcWithoutProof.credentialSubject.endorsementComment.indexOf(
        'DELEGATION'
      ) == 0
    )
      eip712SchemaUrl = obDelegationEip712Schema;
    else eip712SchemaUrl = obRoleEip712Schema;
    primaryType = 'EndorsementCredential';
  } else if (vcWithoutProof.type.indexOf('OpenBadgeCredential') != -1) {
    eip712SchemaUrl = obEip712Schema;
    primaryType = 'OpenBadgeCredential';
  } else {
    eip712SchemaUrl = eip712SchemaMap[Object.keys(keyValue)[0]];
    primaryType = 'VerifiableCredential';
  }

  const proof = {
    type: 'EthereumEip712Signature2021',
    created: new Date().toISOString().slice(0, 19) + 'Z',
    proofPurpose: 'assertionMethod',
    verificationMethod: issuerDid + '#ASSR_1',
    proofValue: hexToBase58btc(signature),
    eip712: {
      messageDataEip712Schema: eip712SchemaUrl,
      domain: {
        name: 'myDid',
        chainId: parseInt(process.env.VUE_APP_CHAIN_ID),
        version: '2',
      },
      primaryType,
    },
  };
  return { ...vcWithoutProof, proof };
}

function hexToBase58btc(value) {
  try {
    const byteArray = Uint8Array.from(
      Buffer.from(value.replace('0x', ''), 'hex')
    );
    return base58btc.encode(byteArray);
  } catch (e) {
    console.log(e);
    return value;
  }
}

function base58btcToHex(value) {
  try {
    const byteArray = base58btc.decode(value);
    return '0x' + Buffer.from(byteArray).toString('hex');
  } catch (e) {
    return value;
  }
}
