<template>
  <div class="py-10 px-16">
    <div class="flex items-center mb-10">
      <p class="text-xl font-bold">
        {{ $t('templates.manageRoleTemplates.title') }}
      </p>
      <button
        class="text-sm bg-white border shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2 ml-auto mr-3"
        @click="
          (showTemplateCreation = true) &&
            (currentTemplateCreateTemplateData = null)
        "
        click-outside-ignore-templatecreation
      >
        <icon name="plus" class="mr-1.5" />{{
          $t('templates.manageRoleTemplates.createButton')
        }}
      </button>
      <button
        class="text-sm bg-secondary text-white shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2"
        @click="showTemplateSender = true"
        click-outside-ignore-templatesender
      >
        <icon name="mail" class="fill-white mr-1.5" />{{
          $t('templates.manageRoleTemplates.sendButton')
        }}
      </button>
    </div>
    <div class="flex flex-wrap gap-5 mb-10">
      <table
        v-if="!roleTemplateListLoading && roleTemplateList.length > 0"
        class="min-w-full border border-gray-300"
      >
        <thead>
          <tr class="text-left text-gray-400">
            <th class="w-1/4 font-normal py-2 px-4 border">
              {{ $t('templates.manageRoleTemplates.columnRoleTitle') }}
            </th>
            <th class="w-1/4 font-normal py-2 px-4 border">
              {{ $t('templates.manageRoleTemplates.columnDescriptionTitle') }}
            </th>
            <th class="w-1/4 font-normal py-2 px-4 border">
              {{ $t('templates.manageRoleTemplates.columnCriteriaTitle') }}
            </th>
            <th class="w-1/4 font-normal py-2 px-4 border">
              {{ $t('templates.manageRoleTemplates.columnValidityTitle') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="roleTemplateList && roleTemplateList.length > 0">
          <tr
            v-for="(template, index) in roleTemplateList"
            :key="template"
            class="bg-gray-light"
          >
            <td class="py-2 px-4 border cursor-pointer">
              <TemplateDisplay
                v-if="template.data && currentDisplayedTemplateIndex == index"
                :templateData="template.data"
                :templateSessions="template.sessions"
                :templateHash="template.templateHash"
                type="Role"
                :index="Number(template.index)"
                @close="currentDisplayedTemplateIndex = -1"
                @delete="
                  () => {
                    currentDisplayedTemplateIndex = -1;
                    refreshTemplates();
                  }
                "
                @send="
                  () => {
                    currentDisplayedTemplateIndex = -1;
                    showTemplateSender = true;
                    currentTemplateSenderTemplateData = template.data;
                  }
                "
                @edit="
                  () => {
                    currentDisplayedTemplateIndex = -1;
                    currentTemplateCreateTemplateData = template;
                    showTemplateCreation = true;
                  }
                "
                click-outside-ignore-templatedisplay-target
                class="cursor-default"
              />
              <div
                @click="currentDisplayedTemplateIndex = index"
                click-outside-ignore-templatedisplay
                class="flex w-full items-center"
              >
                <div class="grow"></div>
                <TemplateRolePreview
                  :name="template.data.name"
                  :emoji="template.data.image"
                  :color="template.data.hexColorRef"
                ></TemplateRolePreview>
              </div>
            </td>
            <td class="py-2 px-4 border">{{ template.data.description }}</td>
            <td class="py-2 px-4 border">{{ template.data.criteria }}</td>
            <td class="py-2 px-4 border">
              {{
                template.data.expirationDate
                  ? template.data.expirationDate
                  : $t('templates.manageRoleTemplates.columnValidityInfinite')
              }}
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="roleTemplateListLoading"
        class="w-full flex justify-center pt-10"
      >
        <Vue3Lottie
          :animationLink="require('/assets/images/loader-round.jpg')"
          :height="100"
          :width="100"
          :speed="1.5"
        />
      </div>
      <div
        v-if="
          !roleTemplateListLoading &&
          roleTemplateList &&
          roleTemplateList.length == 0
        "
        class="mt-20 mx-auto"
      >
        <TemplateAddSection
          type="Role"
          @create="
            (showTemplateCreation = true) &&
              (currentTemplateCreateTemplateData = null)
          "
        ></TemplateAddSection>
      </div>
    </div>
    <TemplateCreate
      v-if="showTemplateCreation"
      type="Role"
      @close="
        (hash) => {
          showTemplateCreation = false;
          currentTemplateCreateTemplateData = null;
          handleTemplateHashFromClose(hash);
        }
      "
      @refresh="
        () => {
          refreshTemplates();
        }
      "
      :templateData="currentTemplateCreateTemplateData"
      click-outside-ignore-templatecreation-target
    />
    <TemplateSend
      v-if="showTemplateSender"
      type="Role"
      @close="
        () => {
          showTemplateSender = false;
          currentTemplateSenderTemplateData = null;
        }
      "
      @reset="
        () => {
          currentTemplateSenderTemplateData = null;
        }
      "
      :templateList="roleTemplateList"
      :templateSelected="currentTemplateSenderTemplateData"
      click-outside-ignore-templatesender-target
    />
  </div>
</template>

<script>
import { ref, computed, watch, inject } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import TemplateDisplay from '../../components/templates/TemplateDisplay.vue';
import TemplateCreate from '../../components/templates/TemplateCreate.vue';
import TemplateSend from '../../components/templates/TemplateSend.vue';
import TemplateRolePreview from '../../components/templates/TemplateRolePreview.vue';
import TemplateAddSection from '../../components/templates/TemplateAddSection.vue';
export default {
  setup() {
    const utils = inject('utils');
    const store = useStore();
    const route = useRoute();
    const currentIssuerInfo = computed(
      () => store.getters.getCurrentIssuerInfo
    );
    const roleTemplateList = computed(() => store.getters.getRoleTemplateList);
    const roleTemplateListLoading = computed(
      () => store.getters.getRoleTemplateListLoading
    );

    const currentDisplayedTemplateIndex = ref(-1);
    const showTemplateCreation = ref(false);
    const showTemplateSender = ref(false);
    const currentTemplateSenderTemplateData = ref(null);
    const currentTemplateCreateTemplateData = ref(null);
    const forcedDisplayTemplateHash = ref(null);

    function handleRouteQuery() {
      if (route.query.add == '1') {
        showTemplateSender.value = false;
        showTemplateCreation.value = true;
      }
      if (route.query.send == '1') {
        showTemplateCreation.value = false;
        showTemplateSender.value = true;
      }
      if (route.query.hash) {
        if (!roleTemplateListLoading.value) {
          currentDisplayedTemplateIndex.value =
            roleTemplateList.value.findIndex(
              (template) =>
                template.templateHash.replace('0x', '') == route.query.hash
            );
        } else {
          forcedDisplayTemplateHash.value = route.query.hash;
        }
      }
    }
    handleRouteQuery();
    watch(route, handleRouteQuery);

    watch([roleTemplateListLoading, roleTemplateList], () => {
      if (
        !roleTemplateListLoading.value &&
        roleTemplateList.value.length > 0 &&
        forcedDisplayTemplateHash.value
      ) {
        currentDisplayedTemplateIndex.value = roleTemplateList.value.findIndex(
          (template) =>
            template.templateHash.replace('0x', '') ==
            forcedDisplayTemplateHash.value
        );
      }
    });

    async function handleTemplateHashFromClose(hash) {
      if (!hash) return;
      if (!roleTemplateListLoading.value && roleTemplateList.value.length > 0) {
        currentDisplayedTemplateIndex.value = roleTemplateList.value.findIndex(
          (template) => template.templateHash.replace('0x', '') == hash
        );
      } else {
        forcedDisplayTemplateHash.value = hash;
      }
    }

    async function refreshTemplates() {
      store.dispatch('getRoleTemplateList');
    }

    return {
      showTemplateCreation,
      showTemplateSender,
      currentTemplateSenderTemplateData,
      currentTemplateCreateTemplateData,
      currentIssuerInfo,
      roleTemplateList,
      roleTemplateListLoading,
      currentDisplayedTemplateIndex,
      refreshTemplates,
      forcedDisplayTemplateHash,
      handleTemplateHashFromClose,
    };
  },
  components: {
    TemplateRolePreview,
    TemplateCreate,
    TemplateSend,
    TemplateDisplay,
    TemplateAddSection,
  },
};
</script>
