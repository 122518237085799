<template>
  <div>
    <div class="fixed top-0 left-0 w-full h-full">
      <div class="absolute w-full h-full bg-black bg-opacity-20"></div>
    </div>
    <div v-if="!waitingSignature && !sendingSuccessful && !sendingInProgress">
      <div
        v-if="templateSelected"
        class="fixed w-modal-md top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-4 py-3 border rounded-xl shadow-md"
      >
        <div class="flex items-center mb-5">
          <p class="font-medium text-black">
            <icon name="user-add" class="mr-1.5" />{{
              $t('templateSend.addRecipientsTxt')
            }}
          </p>
          <div class="grow"></div>
          <div
            @click="triggerCloseEvent"
            class="text-xl text-gray-400 mr-2 cursor-pointer leading-none"
          >
            <icon name="cross" class="text-sm fill-gray-400" />
          </div>
        </div>
        <p class="text-gray-400 mb-1">
          {{ $t('templateSend.shareLinkTxt') }}
        </p>
        <div
          class="border-2 border-gray-200 h-28 overflow-y-scroll no-scrollbar rounded-lg py-2 px-2 mb-2"
          ref="emailListScrollDiv"
        >
          <div v-if="emailList.length > 0" class="flex flex-wrap gap-2 mb-1.5">
            <div
              v-for="(email, index) in emailList"
              :key="index"
              class="bg-violet-50 pl-1.5 pr-1 rounded-md"
            >
              <p class="text-gray-500">
                {{ email
                }}<span
                  @click="removeFromEmailList(index)"
                  class="cursor-pointer"
                  ><icon name="cross" class="fill-gray-400 text-xxs ml-2 pb-1"
                /></span>
              </p>
            </div>
          </div>
          <textarea
            class="w-full border-none border-transparent focus:border-transparent focus:ring-0 resize-none p-0"
            placeholder=""
            rows="10"
            v-model="emailInput"
            @keydown.enter.prevent="treatEmailInput"
            @focusout="treatEmailInput"
          />
        </div>
        <div class="flex items-center mb-8">
          <button
            class="text-sm bg-white border shadow-sm w-max font-medium rounded-lg cursor-pointer align-middle items-center py-1.5 px-2 mr-2"
            @click="$refs.emailFileInput.click()"
          >
            <icon name="file-upload" class="mr-1.5" />{{
              $t('templateSend.importButton')
            }}
          </button>
          <input
            type="file"
            @change="importEmailFileInput"
            ref="emailFileInput"
            class="hidden"
          />
          <p class="text-xs text-gray-500">
            {{ $t('templateSend.importConstraintsTxt') }}
          </p>
          <p>{{ emailFileMessage }}</p>
        </div>
        <div class="flex items-center">
          <div class="grow"></div>
          <button
            @click="sendEmail"
            class="text-sm bg-secondary text-white shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2.5"
            :class="{
              'pointer-events-none opacity-50': emailList.length == 0,
            }"
          >
            {{ $t('templateSend.sendButton') }}
          </button>
        </div>
      </div>
      <div
        v-else
        class="fixed w-modal-md top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white border rounded-xl shadow-md"
      >
        <div class="px-5 py-2">
          <div class="flex items-center">
            <p class="text-gray-500 my-2">
              {{
                $t('templateSend.sendTxt') + ' ' + templateLabel.toLowerCase()
              }}
              /
              <span class="font-medium text-black">
                {{ $t('templateSend.chooseTemplateTxt') }}</span
              >
            </p>
            <div class="grow"></div>
            <div
              @click="triggerCloseEvent"
              class="text-xl text-gray-400 mr-2 cursor-pointer"
            >
              <icon name="cross" />
            </div>
          </div>
          <input
            class="w-full !text-xl border-none outline outline-0 placeholder:text-gray-300 my-2"
            :placeholder="$t('search.searchPlaceholder')"
            maxlength="50"
            v-model="badgeTemplateSearchTerms"
          />
        </div>
        <hr class="border border-bottom" />
        <div class="px-5 pt-2 relative">
          <div
            class="flex flex-wrap w-full gap-4 overflow-y-scroll no-scrollbar h-[28rem] pb-3"
          >
            <div
              v-for="(template, index) in filteredTemplateList"
              :key="template"
              class="rounded-2xl h-min"
            >
              <TemplatePreview
                v-if="template.data && currentIssuerInfo && type != 'Role'"
                :badgeImage="template.data.image"
                :badgeName="template.data.name"
                :badgeLocation="
                  type == 'Participation'
                    ? template.data.eventDetails.location.split(', ')[0]
                      ? template.data.eventDetails.location.split(', ')[0]
                      : $t('templateDisplay.virtualTxt')
                    : null
                "
                :badgeStartDate="
                  type == 'Participation'
                    ? template.data.eventDetails.startDate
                    : null
                "
                :badgeEndDate="
                  type == 'Participation'
                    ? template.data.eventDetails.endDate
                    : null
                "
                :issuerName="currentIssuerInfo.profile.name"
                :type="type"
                :formatSm="type != 'Membership'"
                :enableSelection="true"
                :selected="currentSelectedTemplateIndex == index"
                @click="currentSelectedTemplateIndex = index"
                class="cursor-pointer"
              />
              <TemplateRolePreview
                v-if="template.data && currentIssuerInfo && type == 'Role'"
                :name="template.data.name"
                :emoji="template.data.image"
                :color="template.data.hexColorRef"
                :enableSelection="true"
                :selected="currentSelectedTemplateIndex == index"
                @click="currentSelectedTemplateIndex = index"
                class="cursor-pointer"
              ></TemplateRolePreview>
            </div>
          </div>
          <div class="absolute bottom-0 right-0 flex items-center">
            <div class="grow"></div>
            <button
              @click="
                templateSelected =
                  filteredTemplateList[currentSelectedTemplateIndex].data
              "
              class="text-sm bg-secondary text-white shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2.5 mr-3 mb-2"
            >
              {{ $t('templateSend.selectButton') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="waitingSignature">
      <SignatureHandler
        @ack="waitingSignatureAck = false"
        @close="waitingSignature = false"
        ref="signatureHandlerRef"
      >
        <div v-if="!waitingSignatureAck" class="mt-5 mb-10">
          <div class="justify-center mb-5">
            <Vue3Lottie
              :animationLink="require('/assets/images/loader-app.jpg')"
              :height="160"
              :width="160"
              :speed="0.7"
            />
          </div>
          <p class="text-center text-xl font-bold px-20 mb-1">
            {{ $t('templateSend.confirmDelegCreateTxt') }}
          </p>
          <p class="text-center px-10 mb-4">
            {{ $t('templateSend.onMydidAppTxt') }}
          </p>
        </div>
        <div v-else class="mt-5 mb-10">
          <div class="justify-center mb-5">
            <Vue3Lottie
              :animationLink="require('/assets/images/loader-wait-app.jpg')"
              :height="100"
              :width="100"
              :speed="1"
            />
          </div>
          <p class="text-center text-xl font-bold px-20 mb-4">
            {{ $t('other.waitingAppConnection') }}
          </p>
        </div></SignatureHandler
      >
    </div>
    <div v-else-if="sendingInProgress">
      <BasicSmModal :noCloseOption="true">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-round.jpg')"
            :height="50"
            :width="50"
            :speed="1.5"
          />
        </div>
        <p class="text-center text-gray-400 px-10">
          {{ templateLabel + ' ' + $t('templateSend.sendProgressTxt') }}
        </p>
      </BasicSmModal>
    </div>
    <div v-else-if="sendingSuccessful">
      <BasicSmModal @close="triggerCloseEvent">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-send.jpg')"
            :height="160"
            :width="160"
            :speed="1"
            :loop="false"
          />
        </div>
        <p class="text-center text-xl font-bold px-20 mb-5">
          {{ templateLabel + ' ' + $t('templateSend.successSendTxt') }}
        </p>
      </BasicSmModal>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { useI18n } from 'vue-i18n';
import { createToast } from 'mosha-vue-toastify';
import TemplatePreview from '../../components/templates/TemplatePreview.vue';
import TemplateRolePreview from '../../components/templates/TemplateRolePreview.vue';
import SignatureHandler from '../../components/SignatureHandler.vue';
import BasicSmModal from '../../components/BasicSmModal.vue';
export default {
  props: {
    templateList: Array,
    templateSelected: Object,
    type: String,
  },
  setup(props, context) {
    const store = useStore();
    const utils = inject('utils');
    const api = inject('api');
    const { t } = useI18n();

    const currentIssuerInfo = computed(
      () => store.getters.getCurrentIssuerInfo
    );
    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);
    const currentAddress = computed(() => store.getters.getCurrentAddress);
    const templateSelectedHash = computed(() => {
      if (!props.templateSelected) return null;
      return props.templateList.find(
        (template) => template.data.id == props.templateSelected.id
      ).templateHash;
    });

    const templateLabel = ref(utils.templateTypeToLabel(props.type, useI18n()));
    const signatureHandlerRef = ref();
    const badgeTemplateSearchTerms = ref(null);
    const currentSelectedTemplateIndex = ref(0);
    const emailInput = ref(null);
    const emailList = ref([]);
    const badgeComment = ref(null);
    const emailFile = ref(null);
    const emailFileMessage = ref(null);
    const MAX_SIZE_IN_BYTES = 20 * 1024 * 1024;
    const emailListScrollDiv = ref(null);

    const waitingSignature = ref(false);
    const waitingSignatureAck = ref(false);
    const sendingInProgress = ref(false);
    const sendingSuccessful = ref(false);

    watch(waitingSignature, () => {
      if (waitingSignature.value) waitingSignatureAck.value = true;
    });

    async function sendEmail() {
      ['Participation', 'Membership'].indexOf(
        props.templateSelected.badgeCategory
      ) != -1
        ? sendOpenLink()
        : sendMultipleCustomLink();
    }

    async function sendMultipleCustomLink() {
      if (window._paq)
        window._paq.push([
          'trackEvent',
          `Template ${props.templateSelected.badgeCategory}`,
          'Send multiple custom links',
          'Confirm',
        ]);

      const badgeWithoutProof = utils.badge.createDelegationBadge(
        uuidv4(),
        currentIssuerDid.value + '#SERV_1',
        process.env.VUE_APP_BADGE_BOT_ISSUER_DID,
        props.templateSelected.name.replace(/\0/g, ''),
        templateSelectedHash.value,
        // expiration date is +30 days from now
        new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 19) + 'Z'
      );

      waitingSignature.value = true;

      // Sign verifiable credential
      const typedData = await utils.sign.getVCTypedDataV4(badgeWithoutProof);

      let signature = null;
      let signatures = null;
      try {
        signatures = await signatureHandlerRef.value.sign(
          'MultiSign',
          'signDelegation' + props.type,
          [
            {
              type: 'eth_signTypedData',
              message: typedData,
            },
          ]
        );
      } catch (e) {
        console.log(e);
        waitingSignature.value = null;
        return;
      }
      [signature] = signatures;

      const delegationBadge = await utils.sign.addProofToVerifiableCredential(
        badgeWithoutProof,
        currentIssuerDid.value,
        signature
      );

      // Verifying signature
      const address = await utils.sign.recoverVCTypedSignatureV4(
        delegationBadge
      );

      if (address != currentAddress.value) {
        throw new Error('Signature invalid for verifiable credential');
      }

      waitingSignature.value = false;
      sendingInProgress.value = true;

      // send to badge bot
      api
        .createSession({
          verifiableCredentials: [],
          delegationBadge,
          didLimit: 1,
          sessionNumber: emailList.value.length,
          emails: emailList.value,
          ...(props.templateSelected.eventDetails && {
            startDate: props.templateSelected.eventDetails.startDate,
          }),
          ...(props.templateSelected.eventDetails &&
            props.templateSelected.eventDetails.limit != -1 && {
              didLimit: props.templateSelected.eventDetails.limit,
            }),
        })
        .then(() => {
          if (window._paq)
            window._paq.push([
              'trackEvent',
              `Template ${props.templateSelected.badgeCategory}`,
              'Send multiple custom links',
              'Success',
            ]);
          sendingInProgress.value = false;
          sendingSuccessful.value = true;
          context.emit('reset');
        });
    }

    async function sendOpenLink() {
      if (window._paq)
        window._paq.push([
          'trackEvent',
          `Template ${props.templateSelected.badgeCategory}`,
          'Send open link',
          'Confirm',
        ]);

      sendingInProgress.value = true;
      api
        .sendOpenSessionByEmail({
          emails: emailList.value,
          templateHash: templateSelectedHash.value,
        })
        .then(() => {
          if (window._paq)
            window._paq.push([
              'trackEvent',
              `Template ${props.templateSelected.badgeCategory}`,
              'Send open link',
              'Success',
            ]);
          sendingInProgress.value = false;
          sendingSuccessful.value = true;
          context.emit('reset');
        });
    }

    const filteredTemplateList = computed(() => {
      const list = [];
      if (!props.templateList) return list;

      for (var template of props.templateList) {
        if (
          (badgeTemplateSearchTerms.value &&
            badgeTemplateSearchTerms.value.length > 0 &&
            template.data.name
              .toLowerCase()
              .indexOf(badgeTemplateSearchTerms.value.toLowerCase()) != -1) ||
          !badgeTemplateSearchTerms.value ||
          badgeTemplateSearchTerms.value.length < 2
        ) {
          list.push({ data: template.data });
        }
      }
      if (currentSelectedTemplateIndex.value > list.length - 1) {
        currentSelectedTemplateIndex.value = 0;
      }

      while (list.length < 4) {
        list.push({});
      }

      return list;
    });

    function showToastMessage(text) {
      createToast(text, {
        position: 'bottom-center',
        hideProgressBar: true,
        timeout: 3000,
        transition: 'slide',
        toastBackgroundColor: '#ff4545',
      });
    }

    async function importEmailFileInput(event) {
      const file = event.target.files[0];

      if (
        ['text/csv', 'application/vnd.ms-excel', 'application/json'].indexOf(
          file['type']
        ) == -1
      ) {
        emailFile.value = null;
        emailFileMessage.value = 'Only csv or json files are accepted.';
        return;
      }

      if (file.size > MAX_SIZE_IN_BYTES) {
        emailFile.value = null;
        emailFileMessage.value = 'Max size exceeded.';
        return;
      }

      if (window._paq)
        window._paq.push([
          'trackEvent',
          `Template ${props.templateSelected.badgeCategory}`,
          'Import email list',
          'Success',
        ]);

      emailFile.value = file;
      emailFileMessage.value = null;
      const reader = new FileReader();
      reader.onload = async (e) => {
        let content = e.target.result;

        function extractEmailsFromString(str) {
          // Regular expression to match email addresses
          let regex = /[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}/g;
          return str.match(regex) || [];
        }

        function extractEmailsFromObject(obj) {
          let stringified = JSON.stringify(obj);
          return extractEmailsFromString(stringified);
        }

        if (
          file.type == 'application/json' ||
          content.trim().startsWith('{') ||
          content.trim().startsWith('[')
        ) {
          try {
            let jsonData = JSON.parse(content);
            let emails = extractEmailsFromObject(jsonData);
            emailList.value = [...new Set(emails)];
            emailListScrollDiv.value.scrollTop = 0;
          } catch (err) {
            console.log(err);
            emailFile.value = null;
            emailFileMessage.value = 'Invalid JSON content';
          }
        } else {
          let emails = extractEmailsFromString(content);
          emailList.value = [...new Set(emails)];
          setTimeout(() => {
            emailListScrollDiv.value.scrollTop = 0;
          }, 100);
        }
      };
      reader.readAsText(emailFile.value);
    }

    function treatEmailInput() {
      if (!emailInput.value) return;
      const potentialEmails = emailInput.value.split(/[\s,;]+/);
      const validEmails = potentialEmails.filter((email) =>
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)
      );

      emailList.value = [...new Set([...emailList.value, ...validEmails])]; // new Set() removes duplicate
      emailInput.value = null;
    }

    function removeFromEmailList(index) {
      emailList.value.splice(index, 1);
    }

    function triggerCloseEvent() {
      context.emit('close');
    }

    return {
      signatureHandlerRef,
      currentIssuerInfo,
      triggerCloseEvent,
      badgeTemplateSearchTerms,
      filteredTemplateList,
      currentSelectedTemplateIndex,
      emailInput,
      emailList,
      treatEmailInput,
      removeFromEmailList,
      badgeComment,
      emailFileMessage,
      importEmailFileInput,
      emailListScrollDiv,
      waitingSignature,
      waitingSignatureAck,
      sendingSuccessful,
      sendingInProgress,
      sendEmail,
      templateLabel,
    };
  },
  components: {
    TemplatePreview,
    TemplateRolePreview,
    SignatureHandler,
    BasicSmModal,
  },
};
</script>
