export function createDelegationBadge(
  id,
  issuerMethod,
  userDid,
  delegatedTemplateName,
  delegatedTemplateHash,
  expirationDate
) {
  const badge = {
    '@context': [
      'https://www.w3.org/2018/credentials/v1',
      'https://purl.imsglobal.org/spec/ob/v3p0/context.json',
      'https://w3id.org/security/suites/ed25519-2020/v1',
    ],
    id,
    type: ['VerifiableCredential', 'EndorsementCredential'],
    issuer: {
      id: issuerMethod,
      type: 'Profile',
    },
    issuanceDate: new Date().toISOString().slice(0, 19) + 'Z',
    expirationDate,
    credentialSubject: {
      id: userDid,
      type: 'EndorsementSubject',
      endorsementComment: `DELEGATION::${delegatedTemplateName}::${delegatedTemplateHash.replace(
        '0x',
        ''
      )}`,
    },
    credentialSchema: [
      {
        id: 'https://myntfsid.mypinata.cloud/ipfs/QmXRCpd6hqRY8q8EcPAqjM9jDcmbsyWCr2zAo1DVEfi5uJ',
        type: '1EdTechJsonSchemaValidator2019',
      },
    ],
    mVersion: '4.0',
  };

  return badge;
}

export function createBasicTemplate(
  issuerDID,
  badgeCategory,
  language,
  id,
  name,
  imageUrl,
  description,
  criteria,
  timestamped,
  expirationDate,
  expirationDuration
) {
  const badgeTemplate = {
    '@context': 'https://w3id.org/openbadges/v2',
    '@language': language,
    type: 'BadgeClass',
    id,
    name,
    badgeCategory,
    image: imageUrl,
    description,
    criteria,
    issuerDID,
    timestamped,
    ...(expirationDate && {
      expirationDate: new Date(expirationDate).toISOString().slice(0, 19) + 'Z',
    }),
    ...(expirationDuration && {
      expirationDuration,
    }),
    requiredBadges: [],
    created: new Date().toISOString().slice(0, 19) + 'Z',
    themeVersion: '1.0',
    mVersion: '4.0',
  };

  return badgeTemplate;
}

export function createCommunityTemplate(
  issuerDID,
  badgeCategory,
  language,
  id,
  name,
  imageUrl,
  description,
  criteria
) {
  const badgeTemplate = {
    '@context': 'https://w3id.org/openbadges/v2',
    '@language': language,
    type: 'BadgeClass',
    id,
    name,
    badgeCategory,
    image: imageUrl,
    description,
    criteria,
    issuerDID,
    created: new Date().toISOString().slice(0, 19) + 'Z',
    themeVersion: '1.0',
    mVersion: '4.0',
  };

  return badgeTemplate;
}

export function createMembershipTemplate(
  issuerDID,
  badgeCategory,
  language,
  id,
  name,
  imageUrl,
  description
) {
  const badgeTemplate = {
    '@context': 'https://w3id.org/openbadges/v2',
    '@language': language,
    type: 'BadgeClass',
    id,
    name,
    badgeCategory,
    image: imageUrl,
    description,
    criteria: '',
    issuerDID,
    created: new Date().toISOString().slice(0, 19) + 'Z',
    themeVersion: '1.0',
    mVersion: '4.0',
  };

  return badgeTemplate;
}

export function createParticipationTemplate(
  issuerDID,
  badgeCategory,
  language,
  id,
  name,
  imageUrl,
  description,
  criteria,
  location,
  startDate,
  endDate,
  limit
) {
  const badgeTemplate = {
    '@context': 'https://w3id.org/openbadges/v2',
    '@language': language,
    type: 'BadgeClass',
    id,
    name,
    badgeCategory,
    image: imageUrl,
    description,
    criteria,
    eventDetails: {
      location,
      startDate,
      endDate,
      limit: limit ? Number(limit) : -1,
    },
    issuerDID,
    created: new Date().toISOString().slice(0, 19) + 'Z',
    themeVersion: '1.0',
    mVersion: '4.0',
  };

  return badgeTemplate;
}

export function createRoleTemplate(
  issuerDID,
  badgeCategory,
  language,
  id,
  name,
  imageUrl,
  description,
  criteria,
  expirationDate,
  expirationDuration,
  hexColorRef
) {
  const badgeTemplate = {
    '@context': 'https://w3id.org/openbadges/v2',
    '@language': language,
    type: 'BadgeClass',
    id,
    name,
    badgeCategory,
    image: imageUrl,
    description,
    criteria,
    hexColorRef,
    issuerDID,
    ...(expirationDate && {
      expirationDate: new Date(expirationDate).toISOString().slice(0, 19) + 'Z',
    }),
    ...(expirationDuration && {
      expirationDuration,
    }),
    requiredBadges: [],
    created: new Date().toISOString().slice(0, 19) + 'Z',
    themeVersion: '1.0',
    mVersion: '4.0',
  };

  return badgeTemplate;
}
