import { createRouter, createWebHistory } from 'vue-router';
import store from './store';
import VueCookies from 'vue-cookies';
import jwtDecode from 'jwt-decode';

import Signin from './views/Signin';
import Signup from './views/Signup';
import Search from './views/Search';

import ManageBasicTemplates from './views/templates/ManageBasicTemplates';
import ManageCommunityTemplates from './views/templates/ManageCommunityTemplates';
import ManageParticipationTemplates from './views/templates/ManageParticipationTemplates';
import ManageMembershipTemplates from './views/templates/ManageMembershipTemplates';
import ManageRoleTemplates from './views/templates/ManageRoleTemplates';

import SettingsManageProfile from './views/settings/ManageProfile';
import SettingsManageLanguage from './views/settings/ManageLanguage';
// import SettingsManageNotifications from './views/settings/ManageNotifications';
import SettingsTermsOfService from './views/settings/TermsOfService';
import SettingsPrivacyPolicy from './views/settings/PrivacyPolicy';
import SettingsLegalNotices from './views/settings/LegalNotices';
import SettingsCookieManagementPolicy from './views/settings/CookieManagementPolicy';

import Protected from './views/Protected';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/templates/basic' },
    { path: '/signin', component: Signin },
    { path: '/signup', component: Signup },
    { path: '/search', component: Search },

    { path: '/templates/basic', component: ManageBasicTemplates },
    { path: '/templates/community', component: ManageCommunityTemplates },
    {
      path: '/templates/participation',
      component: ManageParticipationTemplates,
    },
    { path: '/templates/membership', component: ManageMembershipTemplates },
    { path: '/templates/role', component: ManageRoleTemplates },
    { path: '/settings/profile', component: SettingsManageProfile },
    { path: '/settings/language', component: SettingsManageLanguage },
    // { path: '/settings/notifications', component: SettingsManageNotifications },
    { path: '/settings/terms', component: SettingsTermsOfService },
    { path: '/settings/privacy', component: SettingsPrivacyPolicy },
    { path: '/settings/legal', component: SettingsLegalNotices },
    { path: '/settings/cookies', component: SettingsCookieManagementPolicy },
    {
      path: '/protected',
      component: Protected,
    },
    { path: '/:notFound(.*)', redirect: '/' },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (process.env.VUE_APP_PASSWORD && process.env.VUE_APP_PASSWORD.length > 0) {
    // password protection
    const passwordValid =
      VueCookies.get('pwd') &&
      VueCookies.get('pwd') == process.env.VUE_APP_PASSWORD;

    if (passwordValid && to.path == '/protected') {
      return router.push('/');
    }
    if (!passwordValid) {
      VueCookies.remove('pwd');
      VueCookies.remove('token');
      if (to.path != '/protected') return router.push('/protected');
    }
  }

  store.commit(
    'setVerticalBarsDisplay',
    ['/signup', '/signin', '/protected'].indexOf(to.path) == -1
  );

  const token = VueCookies.get('token');

  if ((token && jwtDecode(token).exp < Date.now() / 1000) || !token) {
    VueCookies.remove('token');
    if (
      process.env.VUE_APP_PASSWORD &&
      process.env.VUE_APP_PASSWORD.length > 0
    ) {
      if (['/signup', '/signin', '/protected'].indexOf(to.path) == -1) {
        return router.push('/signin');
      }
    } else {
      if (['/signup', '/signin'].indexOf(to.path) == -1) {
        return router.push('/signin');
      }
    }
  } else {
    if (
      process.env.VUE_APP_PASSWORD &&
      process.env.VUE_APP_PASSWORD.length > 0
    ) {
      if (['/signup', '/signin', '/protected'].indexOf(to.path) != -1) {
        return router.push('/');
      }
    } else {
      if (['/signup', '/signin'].indexOf(to.path) != -1) {
        return router.push('/');
      }
    }
  }

  return next();
});

export default router;
