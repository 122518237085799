<template>
  <div class="flex">
    <div class="relative">
      <CookieConsent></CookieConsent>
    </div>
    <div
      v-if="areVerticalBarsDisplayed"
      :key="currentIssuerDid"
      class="flex-none w-80 min-h-screen filter relative overflow-auto"
    >
      <div class="absolute w-full h-full">
        <nav-bar></nav-bar>
      </div>
    </div>
    <div
      class="flex-1 min-h-screen"
      :class="{
        'pt-5': areVerticalBarsDisplayed,
      }"
      :key="currentIssuerDid"
    >
      <div
        class="relative w-full h-full overflow-auto bg-white"
        :class="{
          'rounded-tl-3xl shadow-md border-t border-l':
            areVerticalBarsDisplayed,
        }"
      >
        <div class="absolute w-full h-full">
          <router-view v-slot="slotProps">
            <component :is="slotProps.Component"></component>
          </router-view>
        </div>
      </div>
    </div>
    <SignatureHandler
      class="hidden"
      @close=""
      @signed=""
      ref="signatureHandlerRef"
    ></SignatureHandler>
  </div>
</template>

<script>
import { ref, inject, provide, watch, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import jwt from 'jsonwebtoken';
import SignatureHandler from './components/SignatureHandler.vue';
import CookieConsent from './components/CookieConsent.vue';
import EventsBus from './plugins/eventBus';

export default {
  setup() {
    const utils = inject('utils');
    const api = inject('api');
    const nostr = inject('nostr');
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const cookies = inject('cookies');
    const { locale } = useI18n();
    const { eventBus } = EventsBus();

    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);
    const currentAddress = computed(() => store.getters.getCurrentAddress);
    const areVerticalBarsDisplayed = computed(
      () => store.getters.areVerticalBarsDisplayed
    );
    const membershipTemplateList = computed(
      () => store.getters.getMembershipTemplateList
    );
    const membershipTemplateListLoading = computed(
      () => store.getters.getMembershipTemplateListLoading
    );
    const signatureHandlerRef = ref();

    function recoverSession() {
      const token = cookies.get('token');
      const nostrPrivateKey = cookies.get('nostr_key');
      const nostrUserPublicKey = cookies.get('nostr_user_key');

      if (token && nostrPrivateKey && nostrUserPublicKey) {
        // restore Nostr session
        nostr.recoverSession(nostrPrivateKey, nostrUserPublicKey);

        // restore global session
        store.commit('setCurrentIssuerDid', jwt.decode(token).did);
        store.commit(
          'setCurrentAddress',
          utils.didToAddress(jwt.decode(token).did)
        );
        store.dispatch('getAllTemplateLists');
        return;
      }

      cookies.remove('token');
      cookies.remove('nostr_key');
      cookies.remove('nostr_user_key');
      store.commit('resetIssuerState');
      nostr.disconnectSession(true);
    }

    const savedLang = localStorage.getItem('lang');
    if (savedLang) {
      locale.value = savedLang;
    }

    // redirect when issuer changes
    watch(currentIssuerDid, () => {
      if (!currentIssuerDid.value) {
        const forceSignup = cookies.get('signup');
        if (forceSignup) {
          cookies.remove('signup');
          router.replace('/signin?new=1');
        } else router.push('/signin');
      } else {
        api.getIssuer(currentIssuerDid.value).then(async (result) => {
          const issuerData = result.data;

          const profileService = await utils.ipfs.getJsonDataFromUrl(
            `https://resolver.balota.fr/1.0/identifiers/${currentIssuerDid.value}?tag=SERV_1&chainId=${process.env.VUE_APP_CHAIN_ID}`
          );

          const profile = await utils.ipfs.getJsonDataFromUrl(
            profileService.serviceEndpoint
          );

          store.commit(
            'setCurrentIssuerInfo',
            Object.assign(issuerData, { profile })
          );
        });
      }
    });

    // do this after watch listener to ensure issuer info are recovered
    recoverSession();

    watch(
      () => eventBus.value.get('nostr'),
      async (val) => {
        const [event] = val ?? [];
        const [eventType, eventData, eventSubType, eventRequestId] = event;

        if (eventType === 'user' && eventSubType == 'Disconnect') {
          await nostr.disconnectSession(true);
          cookies.remove('token');
          cookies.remove('nostr_key');
          cookies.remove('nostr_user_key');
          store.commit('resetIssuerState');
        }
      }
    );

    // onboarding
    watch([membershipTemplateListLoading, route], () => {
      if (
        currentIssuerDid.value &&
        !membershipTemplateListLoading.value &&
        membershipTemplateList.value &&
        membershipTemplateList.value.length == 0
      ) {
        if (!route.path.startsWith('/templates/membership'))
          router.push('/templates/membership');
      }
    });

    return {
      currentIssuerDid,
      currentAddress,
      areVerticalBarsDisplayed,
      route,
      signatureHandlerRef,
    };
  },
  components: {
    SignatureHandler,
    CookieConsent,
  },
};
</script>
