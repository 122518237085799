<template>
  <TemplatePreview
    :badgeImage="templateData.image"
    :badgeName="templateData.name"
    :badgeLocation="
      type == 'Participation'
        ? templateData.eventDetails.location.split(', ')[0]
          ? templateData.eventDetails.location.split(', ')[0]
          : $t('templateDisplay.virtualTxt')
        : null
    "
    :badgeStartDate="
      type == 'Participation' ? templateData.eventDetails.startDate : null
    "
    :badgeEndDate="
      type == 'Participation' ? templateData.eventDetails.endDate : null
    "
    :badgeSessions="templateSessions"
    :issuerName="issuerName"
    :type="type"
  />
</template>

<script>
import TemplatePreview from './TemplatePreview.vue';
export default {
  props: {
    templateData: Object,
    templateSessions: Array,
    issuerName: String,
    type: String,
  },
  components: { TemplatePreview },
};
</script>
