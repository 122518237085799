<template>
  <div class="py-10 px-16">
    <div class="flex items-center mb-10">
      <p class="text-xl font-bold">
        {{ $t('templates.manageParticipationTemplates.title') }}
      </p>
      <button
        class="text-sm bg-white border shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2 ml-auto mr-3"
        @click="
          (showTemplateCreation = true) &&
            (currentTemplateCreateTemplateData = null)
        "
        click-outside-ignore-templatecreation
      >
        <icon name="plus" class="mr-1.5" />
        {{ $t('templates.manageParticipationTemplates.createButton') }}
      </button>
      <button
        class="text-sm bg-secondary text-white shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2"
        @click="showTemplateSender = true"
        click-outside-ignore-templatesender
      >
        <icon name="mail" class="fill-white mr-1.5" />
        {{ $t('templates.manageParticipationTemplates.sendButton') }}
      </button>
    </div>
    <div class="flex border-b border-gray-600 border-opacity-20 w-full mb-10">
      <p
        @click="ongoingSelected = true"
        class="mr-10 cursor-pointer"
        :class="
          ongoingSelected
            ? 'border-b-2 border-black font-medium pb-4 '
            : 'text-gray-600'
        "
      >
        {{ $t('templates.manageParticipationTemplates.ongoingSectionButton') }}
      </p>
      <p
        @click="ongoingSelected = false"
        class="cursor-pointer"
        :class="
          !ongoingSelected
            ? 'border-b-2 border-black font-medium pb-4 '
            : 'text-gray-600'
        "
      >
        {{ $t('templates.manageParticipationTemplates.finishedSectionButton') }}
      </p>
    </div>
    <div class="flex flex-wrap gap-5 mb-10">
      <div
        v-if="!participationTemplateListLoading && participationTemplateList"
        v-for="(template, index) in participationTemplateList.filter(
          (template) =>
            ongoingSelected
              ? new Date(template.data.eventDetails.endDate) > new Date()
              : new Date(template.data.eventDetails.endDate) <= new Date()
        )"
        :key="template.index"
      >
        <TemplateDisplay
          v-if="template.data && currentDisplayedTemplateIndex == index"
          :templateData="template.data"
          :templateSessions="template.sessions"
          :templateHash="template.templateHash"
          type="Participation"
          :index="Number(template.index)"
          @close="currentDisplayedTemplateIndex = -1"
          @delete="
            () => {
              currentDisplayedTemplateIndex = -1;
              refreshTemplates();
            }
          "
          @send="
            () => {
              currentDisplayedTemplateIndex = -1;
              showTemplateSender = true;
              currentTemplateSenderTemplateData = template.data;
            }
          "
          @edit="
            () => {
              currentDisplayedTemplateIndex = -1;
              currentTemplateCreateTemplateData = template;
              showTemplateCreation = true;
            }
          "
          click-outside-ignore-templatedisplay-target
        />
        <TemplateCard
          v-if="template.data && currentIssuerInfo"
          :templateData="template.data"
          :templateSessions="template.sessions"
          :issuerName="currentIssuerInfo.profile.name"
          type="Participation"
          @click="currentDisplayedTemplateIndex = index"
          class="cursor-pointer"
          click-outside-ignore-templatedisplay
        />
      </div>
      <div v-else class="w-full flex justify-center pt-10">
        <Vue3Lottie
          :animationLink="require('/assets/images/loader-round.jpg')"
          :height="100"
          :width="100"
          :speed="1.5"
        />
      </div>
      <div
        v-if="
          !participationTemplateListLoading &&
          participationTemplateList &&
          participationTemplateList.length == 0
        "
        class="mt-20 mx-auto"
      >
        <TemplateAddSection
          type="Participation"
          @create="
            (showTemplateCreation = true) &&
              (currentTemplateCreateTemplateData = null)
          "
        ></TemplateAddSection>
      </div>
    </div>
    <TemplateCreate
      v-if="showTemplateCreation"
      type="Participation"
      @close="
        (hash) => {
          showTemplateCreation = false;
          currentTemplateSenderTemplateData = null;
          handleTemplateHashFromClose(hash);
        }
      "
      @refresh="
        () => {
          refreshTemplates();
          currentTemplateSenderTemplateData = null;
        }
      "
      :templateData="currentTemplateCreateTemplateData"
      click-outside-ignore-templatecreation-target
    />
    <TemplateSend
      v-if="showTemplateSender"
      type="Participation"
      @close="
        () => {
          showTemplateSender = false;
          currentTemplateSenderTemplateData = null;
        }
      "
      @reset="
        () => {
          currentTemplateSenderTemplateData = null;
        }
      "
      :templateList="participationTemplateList"
      :templateSelected="currentTemplateSenderTemplateData"
      click-outside-ignore-templatesender-target
    />
  </div>
</template>

<script>
import { ref, computed, watch, inject } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import TemplateDisplay from '../../components/templates/TemplateDisplay.vue';
import TemplateCard from '../../components/templates/TemplateCard.vue';
import TemplateCreate from '../../components/templates/TemplateCreate.vue';
import TemplateSend from '../../components/templates/TemplateSend.vue';
import TemplateAddSection from '../../components/templates/TemplateAddSection.vue';
export default {
  setup() {
    const utils = inject('utils');
    const store = useStore();
    const route = useRoute();
    const currentIssuerInfo = computed(
      () => store.getters.getCurrentIssuerInfo
    );
    const participationTemplateList = computed(
      () => store.getters.getParticipationTemplateList
    );
    const participationTemplateListLoading = computed(
      () => store.getters.getParticipationTemplateListLoading
    );

    const currentDisplayedTemplateIndex = ref(-1);
    const ongoingSelected = ref(true);
    const showTemplateCreation = ref(false);
    const showTemplateSender = ref(false);
    const currentTemplateSenderTemplateData = ref(null);
    const currentTemplateCreateTemplateData = ref(null);
    const forcedDisplayTemplateHash = ref(null);

    function handleRouteQuery() {
      if (route.query.add == '1') {
        showTemplateSender.value = false;
        showTemplateCreation.value = true;
      }
      if (route.query.send == '1') {
        showTemplateCreation.value = false;
        showTemplateSender.value = true;
      }
      if (route.query.hash) {
        if (!participationTemplateListLoading.value) {
          currentDisplayedTemplateIndex.value =
            participationTemplateList.value.findIndex(
              (template) =>
                template.templateHash.replace('0x', '') == route.query.hash
            );
        } else {
          forcedDisplayTemplateHash.value = route.query.hash;
        }
      }
    }
    handleRouteQuery();
    watch(route, handleRouteQuery);

    watch([participationTemplateListLoading, participationTemplateList], () => {
      if (
        !participationTemplateListLoading.value &&
        participationTemplateList.value.length > 0 &&
        forcedDisplayTemplateHash.value
      ) {
        currentDisplayedTemplateIndex.value =
          participationTemplateList.value.findIndex(
            (template) =>
              template.templateHash.replace('0x', '') ==
              forcedDisplayTemplateHash.value
          );
      }
    });

    async function handleTemplateHashFromClose(hash) {
      if (!hash) return;
      if (
        !participationTemplateListLoading.value &&
        participationTemplateList.value.length > 0
      ) {
        currentDisplayedTemplateIndex.value =
          participationTemplateList.value.findIndex(
            (template) => template.templateHash.replace('0x', '') == hash
          );
      } else {
        forcedDisplayTemplateHash.value = hash;
      }
    }

    async function refreshTemplates() {
      store.dispatch('getParticipationTemplateList');
    }

    return {
      ongoingSelected,
      showTemplateCreation,
      showTemplateSender,
      currentTemplateSenderTemplateData,
      currentTemplateCreateTemplateData,
      currentIssuerInfo,
      participationTemplateList,
      participationTemplateListLoading,
      currentDisplayedTemplateIndex,
      refreshTemplates,
      forcedDisplayTemplateHash,
      handleTemplateHashFromClose,
    };
  },
  components: {
    TemplateDisplay,
    TemplateCard,
    TemplateCreate,
    TemplateSend,
    TemplateAddSection,
  },
};
</script>
