import { createApp } from 'vue';

import './styles.css';
import './colorPicker.css';
import './emojiPicker.css';
import 'mosha-vue-toastify/dist/style.css';
import '@fortawesome/fontawesome-free/js/all.js';

import VueCookies from 'vue-cookies';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import VueCountryRegionSelect from 'vue3-country-region-select';
import QrcodeVue from 'qrcode.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import CountryFlag from 'vue-country-flag-next';
import { Vue3Lottie } from 'vue3-lottie';
import VueMatomo from 'vue-matomo';

import router from './router.js';
import store from './store';
import api from './plugins/api.js';
import i18n from './plugins/i18n.js';
import utils from './plugins/utils/index.js';
import nostr from './plugins/nostr';
import clickOutside from './plugins/clickOutside';

import App from './App.vue';
import NavBar from './components/NavBar.vue';
import NostrHandler from './components/NostrHandler.vue';
import Icon from './components/Icon.vue';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(api);
app.use(utils);
app.use(i18n);
app.use(nostr);
app.use(VueCountryRegionSelect);
app.use(VueMatomo, {
  host: 'https://mamoto.mydid.com',
  siteId: 4,
  router,
  trackerUrl: 'https://mamoto.mydid.com/mydid-00000.php',
  trackerScriptUrl: 'https://mamoto.mydid.com/javascript.php',
  requireConsent: true,
});

app.config.devtools = true;

app.provide('cookies', VueCookies);
app.component('nav-bar', NavBar);
app.component('nostr-handler', NostrHandler);
app.component('qrcode-vue', QrcodeVue);
app.component('clip-loader', ClipLoader);
app.component('datepicker', VueDatePicker);
app.component('country-flag', CountryFlag);
app.component('icon', Icon);
app.component('Vue3Lottie', Vue3Lottie);

app.directive('click-outside', clickOutside);

app.mount('#app');
