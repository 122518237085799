<template>
  <div>
    <div v-if="currentStep != 7" class="flex h-28 border-b items-center px-12">
      <div class="flex">
        <img
          :src="require('/assets/images/small_mydid.png')"
          alt=""
          class="w-8 self-center mr-2"
        /><img
          :src="require('/assets/images//mydid-logo.png')"
          alt=""
          class="w-32 self-center"
        />
      </div>
      <div class="grow"></div>
      <div class="">
        <router-link to="/signin">
          <button class="text-secondary font-bold cursor-pointer text-lg">
            {{ $t('signup.signinButton') }}
          </button></router-link
        >
      </div>
    </div>
    <div class="flex justify-center mt-16">
      <div v-if="currentStep == 4" class="text-center">
        <p class="text-xl font-bold mb-2">
          {{ $t('signup.getAcquaintedTxt') }}
        </p>
        <p class="text-gray-400 mb-16">
          {{ $t('signup.privateDataTxt') }}
        </p>
        <div class="w-96 mb-4">
          <input
            type="text"
            :placeholder="$t('signup.lastNamePlaceholder')"
            class="w-full outline-none rounded-lg border border-gray-300 py-4 px-3"
            v-model="contactLastName"
            maxlength="50"
          />
        </div>
        <div class="w-96 mb-4">
          <input
            type="text"
            :placeholder="$t('signup.firstNamePlaceholder')"
            class="w-full outline-none rounded-lg border border-gray-300 py-4 px-3"
            v-model="contactFirstName"
            maxlength="30"
          />
        </div>
        <div class="w-96 mb-5">
          <input
            type="text"
            :placeholder="$t('signup.emailPlaceholder')"
            class="w-full outline-none rounded-lg border border-gray-300 py-4 px-3"
            v-model="contactEmail"
          />
        </div>
        <div class="w-96 flex mb-5 gap-2">
          <input
            type="checkbox"
            class="w-5 h-5 rounded-sm border-2 border-gray-300 outline-none cursor-pointer"
            v-model="termsAccepted"
          />
          <p class="text-gray-400 text-start text-xs">
            {{ $t('signup.termsTxt1') }}
            <span class="underline"
              ><a
                href="https://balota.fr/legal/terms-us.html"
                target="_blank"
                >{{ $t('signup.termsTxt2') }}</a
              ></span
            >
            {{ $t('signup.termsTxt3') }}
            <span class="underline"
              ><a
                href="https://balota.fr/legal/privacy-us.html"
                target="_blank"
                >{{ $t('signup.termsTxt4') }}</a
              ></span
            >
            {{ $t('signup.termsTxt5') }}
          </p>
        </div>
        <div class="w-96 flex mb-12 gap-2">
          <input
            type="checkbox"
            class="w-5 h-5 rounded-sm border-2 border-gray-300 outline-none cursor-pointer"
            v-model="contactOptIn"
          />
          <p class="text-gray-400 text-start text-xs">
            {{ $t('signup.optInText') }}
          </p>
        </div>

        <div class="w-96">
          <button
            @click="validateStep(1) ? (currentStep = 5) : null"
            class="w-full bg-secondary text-white font-bold rounded-lg cursor-pointer py-3 px-10"
          >
            {{ $t('signup.nextButton') }}
          </button>
        </div>
      </div>
      <div v-if="currentStep == 5" class="text-center">
        <p class="text-xl font-bold mb-2">{{ $t('signup.title') }}</p>
        <p class="text-gray-400 mb-16">
          {{ $t('signup.publicInfoTxt') }}
        </p>
        <div class="w-96 mb-7 flex justify-center items-center gap-3">
          <div>
            <img
              v-if="issuerPicturePreview"
              :src="issuerPicturePreview"
              alt="profile_picture"
              class="w-24 h-24 bg-gray-200 rounded-full"
            />
            <div v-else class="w-24 h-24 bg-gray-400 rounded-full"></div>
          </div>
          <div class="text-start">
            <p class="mb-2">{{ $t('signup.logoTitle') }}</p>
            <div class="flex gap-2 mb-2">
              <button
                class="text-sm bg-white border shadow-md font-medium rounded-lg cursor-pointer py-1 px-2"
                @click="showImageHandler = true"
              >
                <icon name="image" class="mr-1.5" />{{
                  $t('signup.importButton')
                }}
              </button>
              <button
                class="text-sm bg-white border shadow-md font-medium rounded-lg cursor-pointer py-1 px-2"
                :class="{
                  'pointer-events-none opacity-50': !issuerPicturePreview,
                }"
                @click="deleteIssuerPictureInput"
              >
                {{ $t('signup.deleteButton') }}
              </button>
            </div>
            <p class="text-xs text-gray-600">
              {{ $t('signup.logoConstraints') }}
            </p>
            <p v-if="issuerPictureMessage" class="text-xs text-red-600">
              {{ issuerPictureMessage }}
            </p>
          </div>
        </div>
        <div class="w-96 mb-4">
          <input
            type="text"
            :placeholder="$t('signup.namePlaceholder')"
            class="w-full outline-none rounded-lg border border-gray-300 py-4 px-3"
            v-model="issuerName"
            maxlength="100"
          />
        </div>
        <div class="w-96 mb-4">
          <input
            type="text"
            :placeholder="$t('signup.sectorPlaceholder')"
            class="w-full outline-none rounded-lg border border-gray-300 py-4 px-3"
            v-model="issuerSector"
            maxlength="100"
          />
        </div>
        <div class="w-96 mb-5">
          <textarea
            type="text"
            :placeholder="$t('signup.descriptionPlaceholder')"
            rows="5"
            class="w-full outline-none rounded-lg border border-gray-300 py-4 px-3"
            v-model="issuerDescription"
            maxlength="1000"
          />
        </div>
        <div class="w-96 mb-3">
          <button
            @click="validateStep(2) ? (currentStep = 6) : null"
            class="w-full bg-secondary text-white font-bold rounded-lg cursor-pointer py-3 px-10"
          >
            {{ $t('signup.nextButton') }}
          </button>
        </div>
        <div class="w-96">
          <button
            @click="currentStep = 4"
            class="w-full bg-white border border-black font-bold rounded-lg cursor-pointer py-3 px-10"
          >
            {{ $t('signup.previousButton') }}
          </button>
        </div>
      </div>
      <div v-if="currentStep == 6" class="text-center">
        <p class="text-xl font-bold mb-2">{{ $t('signup.networkTitle') }}</p>
        <p class="text-gray-400 mb-16">{{ $t('signup.optionnalTxt') }}</p>
        <div class="w-96 mb-4">
          <input
            type="text"
            :placeholder="$t('signup.discordPlaceholder')"
            class="w-full outline-none rounded-lg border border-gray-300 py-4 px-3"
            v-model="issuerDiscord"
          />
        </div>
        <div class="w-96 mb-4">
          <input
            type="text"
            :placeholder="$t('signup.twitterPlaceholder')"
            class="w-full outline-none rounded-lg border border-gray-300 py-4 px-3"
            v-model="issuerTwitter"
          />
        </div>
        <div class="w-96 mb-16">
          <input
            type="text"
            :placeholder="$t('signup.websitePlaceholder')"
            class="w-full outline-none rounded-lg border border-gray-300 py-4 px-3"
            v-model="issuerWebsite"
          />
        </div>
        <div class="w-96 mb-3">
          <button
            @click="
              validateStep(3) ? (currentStep = 7) && createIssuer() : null
            "
            class="w-full bg-secondary text-white font-bold rounded-lg cursor-pointer py-3 px-10"
          >
            {{ $t('signup.nextButton') }}
          </button>
        </div>
        <div class="w-96 mb-10">
          <button
            @click="currentStep = 5"
            class="w-full bg-white border border-black font-bold rounded-lg cursor-pointer py-3 px-10"
          >
            {{ $t('signup.previousButton') }}
          </button>
        </div>
        <p
          @click="emptyNetworks() && (currentStep = 7) && createIssuer()"
          class="font-bold text-secondary cursor-pointer mb-16"
        >
          {{ $t('signup.skipButton') }}
        </p>
      </div>
      <div v-if="currentStep == 7" class="text-center w-96 pt-52">
        <div class="flex justify-center mb-12">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-round.jpg')"
            :height="100"
            :width="100"
            :speed="1.5"
          />
        </div>
        <div
          @click="currentStep = 4"
          class="fixed top-0 right-0 cursor-pointer p-10"
        >
          <icon name="cross" class="text-xl" />
        </div>
        <p v-if="ipfsLoading" class="text-3xl font-bold mb-12">
          {{ $t('settings.manageProfile.profileTxt') }}
          {{ $t('templateCreation.uploadInProgressTxt') }}
        </p>
        <p
          v-else-if="waitingSignature && waitingSignatureAck"
          class="text-3xl font-bold mb-12"
        >
          {{ $t('other.waitingAppConnection') }}
        </p>
        <p
          v-else-if="waitingSignature && !waitingSignatureAck"
          class="text-3xl font-bold mb-12"
        >
          {{ $t('signup.checkAppTxt') }}
        </p>
        <p v-else-if="waitingTransaction" class="text-3xl font-bold mb-12">
          {{ $t('signup.communityReadyTxt') }}
        </p>
        <SignatureHandler
          @ack="waitingSignatureAck = false"
          ref="signatureHandlerRef"
          class="hidden"
        ></SignatureHandler>
      </div>
    </div>
  </div>
  <ImageHandler
    v-if="showImageHandler"
    :noGallery="true"
    @select="
      (file, data) => {
        issuerPicturePreview = data;
        issuerPictureFile = file;
        showImageHandler = false;
      }
    "
    @close="showImageHandler = false"
  ></ImageHandler>
</template>

<script>
import { ref, inject, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { createToast } from 'mosha-vue-toastify';
import { useI18n } from 'vue-i18n';
import SignatureHandler from '../components/SignatureHandler.vue';
import ImageHandler from '../components/ImageHandler.vue';
import EventsBus from '../plugins/eventBus';
import blankProfileAvatar from '/assets/images/blank-profile-avatar.png';

export default {
  setup() {
    const api = inject('api');
    const utils = inject('utils');
    const cookies = inject('cookies');
    const nostr = inject('nostr');
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { eventBus } = EventsBus();

    const currentAddress = computed(() => store.getters.getCurrentAddress);
    const currentPublicKeyBase58 = computed(
      () => store.getters.getCurrentPublicKeyBase58
    );
    const signatureHandlerRef = ref();

    const currentStep = ref(4);
    const storeSelected = ref(true);

    const contactLastName = ref(null);
    const contactFirstName = ref(null);
    const contactEmail = ref(null);
    const contactOptIn = ref(true);

    const issuerPictureFile = ref(null);
    const issuerPictureMessage = ref(null);
    const issuerPicturePreview = ref(null);

    const issuerName = ref(null);
    const issuerSector = ref(null);
    const issuerDescription = ref(null);

    const issuerDiscord = ref(null);
    const issuerTwitter = ref(null);
    const issuerWebsite = ref(null);

    const issuerProfileIpfs = ref(null);
    const ipfsLoading = ref(false);
    const waitingSignature = ref(false);
    const waitingSignatureAck = ref(false);
    const waitingTransaction = ref(false);
    const showImageHandler = ref(false);
    const termsAccepted = ref(false);

    if (!currentAddress.value) {
      router.push('/signin?new=1');
    }

    // handle default profile image
    fetch(blankProfileAvatar)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          const base64data = reader.result;
          issuerPictureFile.value = utils.base64ToFile(
            base64data,
            'blank-profile-avatar'
          );

          const reader2 = new FileReader();
          reader2.readAsDataURL(issuerPictureFile.value);
          reader2.onload = async (e) => {
            issuerPicturePreview.value = e.target.result;
          };
        };
      });

    function showToastMessage(text) {
      createToast(text, {
        position: 'bottom-center',
        hideProgressBar: true,
        timeout: 3000,
        transition: 'slide',
        toastBackgroundColor: '#ff4545',
      });
    }

    function checkStep(step) {
      if (step == 1) {
        if (!contactLastName.value) throw t('signup.errors.lastNameMissing');
        if (contactLastName.value.length > 50)
          throw t('signup.errors.lastNameTooLong');

        if (!contactFirstName.value) throw t('signup.errors.firstNameMissing');
        if (contactFirstName.value.length > 30)
          throw t('signup.errors.firstNameTooLong');

        if (!contactEmail.value) throw t('signup.errors.emailMissing');

        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        if (!emailRegex.test(contactEmail.value))
          throw t('signup.errors.emailNotValid');

        if (!termsAccepted.value) throw t('signup.errors.termsNotValid');

        return true;
      }
      if (step == 2) {
        if (!issuerPictureFile.value) throw t('signup.errors.pictureMissing');

        if (!issuerName.value) throw t('signup.errors.nameMissing');
        if (issuerName.value.length > 100) throw t('signup.errors.nameTooLong');

        const issuerNameRegex =
          /[mM](\.| |-|_)?([yY](\.| |-|_)?)([dD](\.| |-|_)?)([iI](\.| |-|_)?)[dD]/;
        if (issuerNameRegex.test(issuerName.value))
          throw t('signup.errors.nameInvalid');

        if (!issuerSector.value) throw t('signup.errors.sectorMissing');
        if (issuerSector.value.length > 100)
          throw t('signup.errors.sectorTooLong');

        if (!issuerDescription.value)
          throw t('signup.errors.descriptionMissing');
        if (issuerDescription.value.length > 1000)
          throw t('signup.errors.descriptionTooLong');

        return true;
      }
      if (step == 3) {
        const discordRegex =
          /^(https?:\/\/)?(www\.)?(discord\.(gg|io|me|li)|discordapp\.com\/invite)\/.+[a-z]$/;
        if (issuerDiscord.value && !discordRegex.test(issuerDiscord.value))
          throw t('signup.errors.discordInvalid');

        const twitterRegex =
          /^(https?:\/\/)?(twitter|x).com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+)$/;
        if (issuerTwitter.value && !twitterRegex.test(issuerTwitter.value))
          throw t('signup.errors.twitterInvalid');

        const websiteRegex =
          /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
        if (issuerWebsite.value && !websiteRegex.test(issuerWebsite.value))
          throw t('signup.errors.websiteInvalid');

        return true;
      }
    }

    function validateStep(step) {
      try {
        checkStep(step);
      } catch (err) {
        showToastMessage(err);
        return false;
      }
      return true;
    }

    function emptyNetworks() {
      issuerDiscord.value = null;
      issuerTwitter.value = null;
      issuerWebsite.value = null;
      return true;
    }

    function deleteIssuerPictureInput() {
      issuerPictureFile.value = null;
      issuerPictureMessage.value = null;
      issuerPicturePreview.value = null;
    }

    async function createIssuer() {
      ipfsLoading.value = true;
      issuerProfileIpfs.value = null;

      // push image on IPFS
      const issuerPictureIpfsHash = await utils.ipfs.uploadIpfsFile(
        issuerPictureFile.value
      );

      // push textfile on IPFS
      const issuerProfile = {
        type: 'Issuer Profile',
        name: issuerName.value,
        sector: issuerSector.value,
        description: issuerDescription.value,
        website: issuerWebsite.value ? issuerWebsite.value : '',
        twitter: issuerTwitter.value ? issuerTwitter.value : '',
        discord: issuerDiscord.value ? issuerDiscord.value : '',
        profileImage: utils.ipfs.getUrlFromCID(
          utils.ipfs.hashToCID(issuerPictureIpfsHash)
        ),
      };
      const issuerProfileIpfsHash =
        '0x' +
        (await utils.ipfs.uploadIpfsJsonData(JSON.stringify(issuerProfile)));
      issuerProfileIpfs.value = utils.ipfs.getUrlFromCID(
        utils.ipfs.hashToCID(issuerProfileIpfsHash)
      );

      ipfsLoading.value = false;
      waitingSignature.value = true;
      waitingSignatureAck.value = true;

      // create transaction to create issuer
      const methodName = 'createIssuerDID';
      const methodArgs = [
        { type: 'address', value: currentAddress.value },
        { type: 'bytes32', value: issuerProfileIpfsHash },
        {
          type: 'bytes',
          value: cookies.get('authentication_key'),
        },
        { type: 'uint', value: 0x01 }, // default level
      ];

      const message = await utils.broadcast.broadcastEncode(
        currentAddress.value,
        methodName,
        ...methodArgs
      );

      let signature = null;
      let signatures = null;
      try {
        signatures = await signatureHandlerRef.value.sign(
          'MultiSign',
          'signCreateIssuer',
          [
            {
              type: 'personal_sign',
              message,
            },
          ]
        );
      } catch (e) {
        console.log(e);
        waitingSignature.value = null;
        return;
      }

      [signature] = signatures;

      waitingSignature.value = false;
      waitingTransaction.value = true;

      // subscribe issuer before sending transaction
      try {
        await api.createSubscriptionForIssuer({
          address: currentAddress.value,
        });
      } catch (e) {
        console.log(e);
        showToastMessage(t('other.serverError'));
        currentStep.value = 4;
        return;
      }

      // call broadcaster service
      const broadcastResult = await utils.broadcast.sendTransaction(
        currentAddress.value,
        methodName + 'Signed',
        methodArgs.map((item) => item.value),
        signature
      );

      if (broadcastResult.status == 'fail') {
        showToastMessage(t('other.transactionError'));
        currentStep.value = 4;
        waitingTransaction.value = false;
        return;
      }

      api.addIpfsData({
        context: 'issuer-create',
        hash: issuerProfileIpfsHash,
        data: issuerProfile,
      });

      const issuerDid = 'did:mydid:' + currentPublicKeyBase58.value;

      api
        .createIssuer({
          name: issuerName.value,
          did: issuerDid,
          contact: {
            lastName: contactLastName.value,
            firstName: contactFirstName.value,
            email: contactEmail.value,
            optIn: contactOptIn.value,
          },
        })
        .then(async () => {
          const token = cookies.get('token_tmp');
          cookies.set('token', token);
          cookies.remove('token_tmp');
          cookies.remove('authentication_key');
          await nostr.publishEvent('ProfileUpdated', {});
          window.location.href = '/';
        });
    }

    function onAddressConnected() {
      currentStep.value = 4;
    }

    function showToastMessage(text) {
      createToast(text, {
        position: 'bottom-center',
        hideProgressBar: true,
        timeout: 3000,
        transition: 'slide',
        toastBackgroundColor: '#ff4545',
      });
    }

    return {
      currentAddress,
      currentPublicKeyBase58,
      issuerProfileIpfs,
      createIssuer,
      ipfsLoading,
      waitingSignatureAck,
      waitingSignature,

      currentStep,
      storeSelected,
      router,
      onAddressConnected,

      contactLastName,
      contactFirstName,
      contactEmail,
      contactOptIn,

      issuerPictureFile,
      issuerPictureMessage,
      issuerPicturePreview,
      deleteIssuerPictureInput,
      issuerName,
      issuerSector,
      issuerDescription,

      issuerDiscord,
      issuerTwitter,
      issuerWebsite,

      signatureHandlerRef,
      waitingTransaction,
      showImageHandler,
      termsAccepted,

      validateStep,
      emptyNetworks,
    };
  },
  components: {
    SignatureHandler,
    ImageHandler,
  },
};
</script>
