<template>
  <div>
    <div
      v-if="type == 'Basic' || type == 'Community' || type == 'Participation'"
      class="w-[240px] h-[376px] relative rounded-xl flex flex-col p-3"
      :class="{
        '!w-[184px] !h-[288px] !p-2': formatSm,
        'border-secondary border-4': enableSelection && selected,
        'border-white border-4': enableSelection && !selected,
        'bg-[#241977] text-white': type == 'Basic',
        'bg-[#8280fc]  text-white': type == 'Community',
        'bg-[#f3f3ff]': type == 'Participation',
      }"
    >
      <div
        class="w-full h-full top-0 left-0 absolute flex items-end justify-end"
      >
        <img
          :src="
            type == 'Participation'
              ? require('/assets/images/mydid_watermark_black.png')
              : require('/assets/images/mydid_watermark_white.png')
          "
          alt=""
          class="w-[70px] opacity-15 pb-2"
          :class="type == 'Participation' ? 'opacity-[.07]' : 'opacity-15'"
        />
      </div>
      <div class="flex items-center mb-2">
        <img
          :src="currentIssuerInfo.profile.profileImage"
          alt=""
          class="w-7 h-7 rounded-full mr-1.5"
        />
        <p class="text-sm line-clamp-1">{{ issuerDisplayedName }}</p>

        <p v-if="!formatSm" class="flex-none text-xxs ml-auto">
          <icon
            :name="
              type == 'Basic'
                ? 'badge-basic'
                : type == 'Community'
                ? 'badge-community'
                : 'badge-participation'
            "
            class="mr-0.5"
            :class="type == 'Participation' ? 'fill-black' : 'fill-white'"
          />{{ templateLabel }}
        </p>
      </div>
      <div v-if="badgeImage" class="w-full aspect-square overflow-hidden mb-2">
        <img
          :src="badgeImage"
          alt="badgePicture"
          class="object-cover rounded-lg overflow-hidden h-full w-full"
        />
      </div>
      <div
        v-else
        class="w-full border-gray-400 border rounded-xl aspect-square overflow-hidden"
      ></div>
      <div class="">
        <p
          class="text-left font-medium leading-tight line-clamp-1 mb-1.5"
          :class="!formatSm ? 'text-md' : 'text-xs'"
        >
          {{ badgeName }}
        </p>
        <p
          v-if="type == 'Participation'"
          class="text-left leading-tight line-clamp-1 mb-1.5"
          :class="!formatSm ? 'text-sm' : 'text-xs'"
        >
          {{ badgeLocation ? badgeLocation : '...' }}
        </p>
      </div>
      <div class="grow"></div>
      <div v-if="badgeSessions && type != 'Community'" class="flex">
        <div
          class="text-center bg-black rounded-md w-16 py-1"
          :class="{
            'bg-opacity-30': type == 'Basic',
            'bg-opacity-15': type == 'Community',
            'bg-opacity-[0.05]': type == 'Participation',
          }"
        >
          <p class="text-xs">
            <icon
              name="ongoing"
              class="mr-1.5 w-3"
              :class="type == 'Participation' ? 'fill-black' : 'fill-white'"
            />{{ ongoingSessions }}
          </p>
        </div>
        <div class="grow"></div>
        <div
          class="text-center bg-black rounded-md w-16 py-1"
          :class="{
            'bg-opacity-30': type == 'Basic',
            'bg-opacity-15': type == 'Community',
            'bg-opacity-[0.05]': type == 'Participation',
          }"
        >
          <p class="text-xs">
            {{ deliveredBadges }}
            {{
              deliveredBadges > 1 ? $t('other.ownersTxt') : $t('other.ownerTxt')
            }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="type == 'Membership'"
      class="w-80 rounded-2xl relative flex items-center"
      :class="{
        'border-secondary border-4': enableSelection && selected,
        'border-transparent border-4': enableSelection && !selected,
        '!w-96 !rounded-2xl': formatXl,
      }"
    >
      <div
        v-if="badgeImage"
        class="w-full aspect-[85.60/53.98] rounded-xl flex justify-center items-center overflow-hidden"
      >
        <img
          :src="badgeImage"
          alt="badgePicture"
          class="object-cover min-h-full min-w-full"
        />
      </div>
      <div
        v-else
        class="w-full aspect-[85.60/53.98] bg-gray-light rounded-lg"
        :class="{
          '!w-96 !h-[245px]': formatXl,
          '!rounded-2xl': formatXl,
        }"
      ></div>
      <div
        class="absolute h-10 left-0 top-0 p-2"
        :class="{
          '!h-12': formatXl,
        }"
      >
        <div
          class="backdrop-blur-sm h-full inline-flex bg-[#050931] bg-opacity-40 rounded-md items-center px-2 py-1"
          :class="{
            'px-3 py-2': formatXl,
          }"
        >
          <p
            class="text-white font-medium text-xs"
            :class="{
              '!text-sm': formatXl,
            }"
          >
            {{ issuerDisplayedName }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
export default {
  props: {
    badgeImage: String,
    badgeName: String,
    badgeStartDate: String,
    badgeEndDate: String,
    badgeLocation: String,
    badgeSessions: Array,
    issuerName: String,
    type: String,
    formatSm: Boolean,
    formatXl: Boolean,
    enableSelection: Boolean,
    selected: Boolean,
  },
  setup(props) {
    const utils = inject('utils');
    const store = inject('store');

    const templateLabel = ref(
      utils.templateTypeToLabel(props.type, useI18n(), true)
    );
    const currentIssuerInfo = computed(
      () => store.getters.getCurrentIssuerInfo
    );

    const ongoingSessions = ref(null);
    const deliveredBadges = ref(null);

    const issuerDisplayedName = computed(() => {
      return currentIssuerInfo.value.profile.name.length >= 20
        ? currentIssuerInfo.value.profile.name.substring(0, 17) + '...'
        : currentIssuerInfo.value.profile.name;
    });

    if (props.badgeSessions) {
      ongoingSessions.value = props.badgeSessions.filter((session) => {
        if (session.didLimit != 1) return false;
        return session.didHistory.length == 0;
      }).length;

      deliveredBadges.value = props.badgeSessions.reduce((sum, session) => {
        return sum + session.didHistory.length;
      }, 0);
    }

    return {
      templateLabel,
      issuerDisplayedName,
      currentIssuerInfo,
      ongoingSessions,
      deliveredBadges,
    };
  },
};
</script>
