<template>
  <div class="py-10 px-16">
    <p class="font-bold text-xl mb-5">
      {{ $t('settings.manageProfile.title') }}
    </p>
    <div v-if="issuerRetrieved" class="pt-10">
      <div class="w-96 mb-7 flex items-center gap-3">
        <div>
          <img
            :src="issuerPicturePreview"
            alt="profile_picture"
            class="w-24 h-24 bg-gray-200 rounded-full"
          />
        </div>
        <div class="text-start">
          <p class="mb-2">{{ $t('signup.logoTitle') }}</p>
          <div class="flex gap-2 mb-2">
            <button
              class="text-sm bg-white border shadow-md font-medium rounded-lg cursor-pointer py-1 px-2"
              @click="showImageHandler = true"
            >
              <icon name="image" class="mr-1.5" />{{
                $t('signup.importButton')
              }}
            </button>
          </div>
          <p class="text-xs text-gray-600">
            {{ $t('signup.logoConstraints') }}
          </p>
          <p v-if="issuerPictureMessage" class="text-xs text-red-600">
            {{ issuerPictureMessage }}
          </p>
        </div>
      </div>
      <div class="w-96 mb-3">
        <span class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3">{{
          $t('settings.manageProfile.namePlaceholder')
        }}</span>
        <input
          type="text"
          :placeholder="$t('settings.manageProfile.namePlaceholderBis')"
          class="w-full outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5"
          v-model="issuerName"
        />
      </div>
      <div class="w-96 mb-1.5">
        <span class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3">{{
          $t('settings.manageProfile.descriptionPlaceholder')
        }}</span>
        <textarea
          type="text"
          :placeholder="$t('settings.manageProfile.descriptionPlaceholderBis')"
          class="w-full outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5"
          rows="4"
          v-model="issuerDescription"
        />
      </div>
      <div class="w-96 mb-6">
        <span class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3">{{
          $t('settings.manageProfile.sectorPlaceholder')
        }}</span>
        <input
          type="text"
          :placeholder="$t('settings.manageProfile.sectorPlaceholderBis')"
          class="w-full outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5"
          v-model="issuerSector"
        />
      </div>
      <div class="w-96 mb-3">
        <span class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3">{{
          $t('settings.manageProfile.discordPlaceholder')
        }}</span>
        <input
          type="text"
          :placeholder="$t('settings.manageProfile.discordPlaceholder')"
          class="w-full outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5"
          v-model="issuerDiscord"
        />
      </div>
      <div class="w-96 mb-3">
        <span class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3">{{
          $t('settings.manageProfile.twitterPlaceholder')
        }}</span>
        <input
          type="text"
          :placeholder="$t('settings.manageProfile.twitterPlaceholder')"
          class="w-full outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5"
          v-model="issuerTwitter"
        />
      </div>
      <div class="w-96 mb-5">
        <span class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3">{{
          $t('settings.manageProfile.websitePlaceholder')
        }}</span>
        <input
          type="text"
          :placeholder="$t('settings.manageProfile.websitePlaceholder')"
          class="w-full outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5"
          v-model="issuerWebsite"
        />
      </div>
      <button
        @click="validateProfile() && (waitingProfileConfirmation = true)"
        class="bg-secondary text-white shadow-sm font-medium rounded-lg px-3 py-2 cursor-pointer"
      >
        {{ $t('settings.manageProfile.saveProfileBtn') }}
      </button>
      <!-- <p class="font-bold text-xl mt-20 mb-5">
        {{ $t('settings.manageProfile.assrKeyTitle') }}
      </p>
      <div class="w-96 mb-5">
        <input
          type="text"
          :placeholder="`eip155:100:0x...`"
          class="w-full outline-none border-gray-300 border rounded-lg px-3 py-2"
          v-model="assertionKey"
        />
      </div>
      <button
        @click="setAttribute"
        class="bg-secondary text-white shadow-sm font-medium rounded-lg px-3 py-2 cursor-pointer"
      >
        {{ $t('settings.manageProfile.addAssrKeyBtn') }}
      </button> -->
    </div>
    <div v-if="waitingSignature">
      <div class="fixed top-0 left-0 w-full h-full z-10">
        <div class="absolute w-full h-full bg-black bg-opacity-20"></div>
      </div>
      <SignatureHandler
        @ack="waitingSignatureAck = false"
        @close="waitingSignature = false"
        ref="signatureHandlerRef"
      >
        <div v-if="!waitingSignatureAck" class="mt-5 mb-10">
          <div class="justify-center mb-5">
            <Vue3Lottie
              :animationLink="require('/assets/images/loader-app.jpg')"
              :height="160"
              :width="160"
              :speed="0.7"
            />
          </div>
          <p
            v-if="waitingSignature == 'setAssrAttribute'"
            class="text-center text-xl font-bold px-20 mb-1"
          >
            {{ $t('other.confirmAttributeSign') }}
          </p>
          <p
            v-else-if="waitingSignature == 'updateProfile'"
            class="text-center text-xl font-bold px-20 mb-1"
          >
            {{ $t('other.confirmProfileUpdate') }}
          </p>
          <p class="text-center px-10 mb-4">
            {{ $t('templateCreation.onMydidAppTxt') }}
          </p>
        </div>
        <div v-else class="mt-5 mb-10">
          <div class="justify-center mb-5">
            <Vue3Lottie
              :animationLink="require('/assets/images/loader-wait-app.jpg')"
              :height="100"
              :width="100"
              :speed="1"
            />
          </div>
          <p class="text-center text-xl font-bold px-20 mb-4">
            {{ $t('other.waitingAppConnection') }}
          </p>
        </div></SignatureHandler
      >
    </div>
    <div v-else-if="waitingProfileConfirmation">
      <div class="fixed top-0 left-0 w-full h-full z-10">
        <div class="absolute w-full h-full bg-black bg-opacity-20"></div>
      </div>
      <BasicSmModal @close="waitingProfileConfirmation = false">
        <p class="text-center text-xl font-bold px-5 mb-10">
          {{ $t('settings.manageProfile.updateConfirmTxt') }}
        </p>
        <div class="flex justify-center">
          <button
            @click="updateProfile() && (waitingProfileConfirmation = false)"
            class="bg-secondary text-white shadow-sm font-medium rounded-lg px-3 py-2 cursor-pointer"
          >
            {{ $t('settings.manageProfile.updateConfirmButton') }}
          </button>
        </div>
      </BasicSmModal>
    </div>
    <div v-else-if="waitingTransaction">
      <div class="fixed top-0 left-0 w-full h-full z-10">
        <div class="absolute w-full h-full bg-black bg-opacity-20"></div>
      </div>
      <BasicSmModal :noCloseOption="true">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-round.jpg')"
            :height="50"
            :width="50"
            :speed="1.5"
          />
        </div>
        <p class="text-center text-xl font-bold px-10">
          {{ $t('other.operationInProgressTxt') }}
        </p>
      </BasicSmModal>
    </div>
    <div v-else-if="transactionSuccessful">
      <div class="fixed top-0 left-0 w-full h-full z-10">
        <div class="absolute w-full h-full bg-black bg-opacity-20"></div>
      </div>
      <BasicSmModal @close="transactionSuccessful = false">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-check.jpg')"
            :height="160"
            :width="160"
            :speed="0.5"
            :loop="false"
          />
        </div>
        <p class="text-center text-xl font-bold px-20 mb-5">
          {{ $t('other.changesSuccessfulTxt') }}
        </p>
      </BasicSmModal>
    </div>
    <div v-else-if="waitingIpfs">
      <div class="fixed top-0 left-0 w-full h-full z-10">
        <div class="absolute w-full h-full bg-black bg-opacity-20"></div>
      </div>
      <BasicSmModal :noCloseOption="true">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-round.jpg')"
            :height="50"
            :width="50"
            :speed="1.5"
          />
        </div>
        <p class="text-center text-xl font-bold px-10">
          {{ $t('settings.manageProfile.profileTxt') }}
          {{ $t('templateCreation.uploadInProgressTxt') }}
        </p>
      </BasicSmModal>
    </div>
    <ImageHandler
      v-if="showImageHandler"
      :noGallery="true"
      @select="
        (file, data) => {
          issuerPicturePreview = data;
          issuerPictureFile = file;
          showImageHandler = false;
        }
      "
      @close="showImageHandler = false"
    ></ImageHandler>
  </div>
</template>

<script>
import { ref, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import { useI18n } from 'vue-i18n';
import SignatureHandler from '../../components/SignatureHandler.vue';
import BasicSmModal from '../../components/BasicSmModal.vue';
import ImageHandler from '../../components/ImageHandler.vue';
import { watch } from 'vue';
export default {
  setup() {
    const store = useStore();
    const utils = inject('utils');
    const api = inject('api');
    const nostr = inject('nostr');
    const { t } = useI18n();

    const currentIssuerInfo = computed(
      () => store.getters.getCurrentIssuerInfo
    );
    const currentAddress = computed(() => store.getters.getCurrentAddress);
    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);

    const signatureHandlerRef = ref();
    const waitingProfileConfirmation = ref(false);
    const waitingSignature = ref(false);
    const waitingSignatureAck = ref(false);
    const waitingTransaction = ref(false);
    const waitingIpfs = ref(false);
    const transactionSuccessful = ref(false);
    const issuerRetrieved = ref(false);
    const showImageHandler = ref(false);

    const assertionKey = ref(null);

    const issuerPictureFile = ref(null);
    const issuerPictureMessage = ref(null);
    const issuerPicturePreview = ref(null);

    const issuerName = ref(null);
    const issuerSector = ref(null);
    const issuerDescription = ref(null);
    const issuerWebsite = ref(null);
    const issuerTwitter = ref(null);
    const issuerDiscord = ref(null);

    function initIssuerProfile() {
      issuerName.value = currentIssuerInfo.value.profile.name;
      issuerSector.value = currentIssuerInfo.value.profile.sector;
      issuerDescription.value = currentIssuerInfo.value.profile.description;
      issuerWebsite.value = currentIssuerInfo.value.profile.website;
      issuerTwitter.value = currentIssuerInfo.value.profile.twitter;
      issuerDiscord.value = currentIssuerInfo.value.profile.discord;

      utils
        .getBase64FromImageUrl(currentIssuerInfo.value.profile.profileImage)
        .then((data) => {
          issuerPicturePreview.value = data;
          issuerPictureFile.value = utils.base64ToFile(data);
          issuerRetrieved.value = true;
        });
    }

    if (currentIssuerInfo.value) initIssuerProfile();
    watch(currentIssuerInfo, () => {
      if (currentIssuerInfo.value && !issuerRetrieved.value)
        initIssuerProfile();
    });

    function showToastMessage(text) {
      createToast(text, {
        position: 'bottom-center',
        hideProgressBar: true,
        timeout: 3000,
        transition: 'slide',
        toastBackgroundColor: '#ff4545',
      });
    }

    async function setAttribute() {
      waitingSignature.value = 'setAssrAttribute';

      const methodName = 'setAttribute';
      const methodArgs = [
        {
          type: 'address',
          value: currentAddress.value,
        },
        {
          type: 'bytes32',
          value:
            '0x' +
            (
              '0000000000000000000000000000000000000000000000000000000000000000' +
              utils.asciiToHex(`ASSR,ECK1_RM20,BCAC`).replace('0x', '')
            ).slice(-64),
        },
        {
          type: 'uint',
          value: 99 * 365 * 24 * 60 * 60,
        },
        {
          type: 'string',
          value: assertionKey.value,
        },
      ];

      const message = await utils.broadcast.broadcastEncode(
        currentAddress.value,
        methodName,
        ...methodArgs
      );

      // request signature for broadcast
      let broadcastSignature = null;
      let signatures = null;
      try {
        signatures = await signatureHandlerRef.value.sign(
          'MultiSign',
          'signSetAttribute',
          [
            {
              type: 'personal_sign',
              message,
            },
          ]
        );
      } catch (e) {
        console.log(e);
        waitingSignature.value = null;
        return;
      }

      [broadcastSignature] = signatures;

      waitingSignature.value = false;
      waitingTransaction.value = true;

      utils.broadcast
        .sendTransaction(
          currentAddress.value,
          methodName + 'Signed',
          methodArgs.map((item) => item.value),
          broadcastSignature
        )
        .then((broadcastResult) => {
          if (broadcastResult.status == 'success') {
            waitingTransaction.value = null;
            transactionSuccessful.value = true;
            assertionKey.value = null;
          } else if (broadcastResult.status == 'fail') {
            waitingTransaction.value = null;
            showToastMessage(t('other.transactionError'));
          }
        });
    }

    function validateProfile() {
      try {
        checkProfile();
        return true;
      } catch (err) {
        console.log(err);
        showToastMessage(err);
        return false;
      }
    }

    function checkProfile() {
      if (!issuerPictureFile.value) throw t('signup.errors.pictureMissing');

      if (!issuerName.value) throw t('signup.errors.nameMissing');
      if (issuerName.value.length > 100) throw t('signup.errors.nameTooLong');
      const issuerNameRegex =
        /[mM](\.| |-|_)?([yY](\.| |-|_)?)([dD](\.| |-|_)?)([iI](\.| |-|_)?)[dD]/;
      if (issuerNameRegex.test(issuerName.value))
        throw t('signup.errors.nameInvalid');

      if (!issuerSector.value) throw t('signup.errors.sectorMissing');
      if (issuerSector.value.length > 100)
        throw t('signup.errors.sectorTooLong');

      if (!issuerDescription.value) throw t('signup.errors.descriptionMissing');
      if (issuerDescription.value.length > 1000)
        throw t('signup.errors.descriptionTooLong');

      const discordRegex =
        /^(https?:\/\/)?(www\.)?(discord\.(gg|io|me|li)|discordapp\.com\/invite)\/.+$/;
      if (issuerDiscord.value && !discordRegex.test(issuerDiscord.value))
        throw t('signup.errors.discordInvalid');

      const twitterRegex =
        /^(https?:\/\/)?(twitter|x).com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+)$/;
      if (issuerTwitter.value && !twitterRegex.test(issuerTwitter.value))
        throw t('signup.errors.twitterInvalid');

      const websiteRegex =
        /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
      if (issuerWebsite.value && !websiteRegex.test(issuerWebsite.value))
        throw t('signup.errors.websiteInvalid');

      return true;
    }

    async function updateProfile() {
      waitingIpfs.value = true;

      // push image on IPFS
      const issuerPictureIpfsHash = await utils.ipfs.uploadIpfsFile(
        issuerPictureFile.value
      );

      // push textfile on IPFS
      const issuerProfile = {
        type: 'Issuer Profile',
        name: issuerName.value,
        sector: issuerSector.value,
        description: issuerDescription.value,
        website: issuerWebsite.value ? issuerWebsite.value : '',
        twitter: issuerTwitter.value ? issuerTwitter.value : '',
        discord: issuerDiscord.value ? issuerDiscord.value : '',
        profileImage: utils.ipfs.getUrlFromCID(
          utils.ipfs.hashToCID(issuerPictureIpfsHash)
        ),
      };
      const issuerProfileIpfsHash =
        '0x' +
        (await utils.ipfs.uploadIpfsJsonData(JSON.stringify(issuerProfile)));
      const issuerProfileIpfs = utils.ipfs.getUrlFromCID(
        utils.ipfs.hashToCID(issuerProfileIpfsHash)
      );

      waitingIpfs.value = false;
      waitingSignature.value = 'updateProfile';

      const methodName = 'setAttribute';
      const methodArgs = [
        {
          type: 'address',
          value: currentAddress.value,
        },
        {
          type: 'bytes32',
          value:
            '0x' +
            (
              '0000000000000000000000000000000000000000000000000000000000000000' +
              utils.asciiToHex(`SERV,Public Profile`).replace('0x', '')
            ).slice(-64),
        },
        {
          type: 'uint',
          value: 99 * 365 * 24 * 60 * 60,
        },
        {
          type: 'string',
          value: issuerProfileIpfs,
        },
      ];

      const message = await utils.broadcast.broadcastEncode(
        currentAddress.value,
        methodName,
        ...methodArgs
      );

      // request signature for broadcast
      let broadcastSignature = null;
      let signatures = null;
      try {
        signatures = await signatureHandlerRef.value.sign(
          'MultiSign',
          'signChangeProfile',
          [
            {
              type: 'personal_sign',
              message,
            },
          ]
        );
      } catch (e) {
        console.log(e);
        waitingSignature.value = null;
        return;
      }

      [broadcastSignature] = signatures;

      waitingSignature.value = false;
      waitingTransaction.value = true;

      utils.broadcast
        .sendTransaction(
          currentAddress.value,
          methodName + 'Signed',
          methodArgs.map((item) => item.value),
          broadcastSignature
        )
        .then((broadcastResult) => {
          if (broadcastResult.status == 'success') {
            waitingTransaction.value = null;
            transactionSuccessful.value = true;

            // update issuer information
            api.getIssuer(currentIssuerDid.value).then(async (result) => {
              const issuerData = result.data;

              const profileService = await utils.ipfs.getJsonDataFromUrl(
                `https://resolver.balota.fr/1.0/identifiers/${currentIssuerDid.value}?tag=SERV_1&chainId=${process.env.VUE_APP_CHAIN_ID}`
              );

              const profile = await utils.ipfs.getJsonDataFromUrl(
                profileService.serviceEndpoint
              );

              store.commit(
                'setCurrentIssuerInfo',
                Object.assign(issuerData, { profile })
              );
              nostr.publishEvent('ProfileUpdated', {});
            });
          } else if (broadcastResult.status == 'fail') {
            waitingTransaction.value = null;
            showToastMessage(t('other.transactionError'));
          }
        });
    }

    function showToastMessage(text) {
      createToast(text, {
        position: 'bottom-center',
        hideProgressBar: true,
        timeout: 3000,
        transition: 'slide',
        toastBackgroundColor: '#ff4545',
      });
    }

    return {
      waitingSignature,
      waitingSignatureAck,
      waitingTransaction,
      waitingIpfs,
      waitingProfileConfirmation,
      showImageHandler,
      transactionSuccessful,
      signatureHandlerRef,
      currentIssuerInfo,
      assertionKey,
      setAttribute,
      updateProfile,
      validateProfile,
      issuerRetrieved,
      issuerPictureFile,
      issuerPictureMessage,
      issuerPicturePreview,
      issuerName,
      issuerSector,
      issuerDescription,
      issuerWebsite,
      issuerTwitter,
      issuerDiscord,
    };
  },
  components: {
    SignatureHandler,
    BasicSmModal,
    ImageHandler,
  },
};
</script>
