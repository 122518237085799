import Web3 from 'web3';
import bluebirdPromise from 'bluebird';
import mydidContractABI from '/assets/mydidContractABI.json';
import { getJsonDataFromUrl, getJsonDataFromCID, hashToCID } from '../ipfs';
import createKeccakHash from 'keccak';
import { getSessionsForTemplates } from '../../api';

const web3 = new Web3(process.env.VUE_APP_WEB3_PROVIDER);

const mydidContract = new web3.eth.Contract(
  mydidContractABI,
  process.env.VUE_APP_MYDID_CONTRACT_ADDR
);

export async function getTemplateList(addr, type) {
  const category = [
    'Basic',
    'Community',
    'Participation',
    'Membership',
    'Role',
  ].indexOf(type);
  if (category === -1) throw 'Bad type for template list';

  const templateList = (
    await mydidContract.methods.getIssuerTemplates(addr, category).call()
  ).reverse();
  const CONCURRENCY_LIMIT = 20;

  let templates = await bluebirdPromise.map(
    Array.from({ length: templateList.length }, (_, i) => i), // Create an array from 0 to templateNumber-1
    async (i) => {
      const template = templateList[i];
      if (
        template.templateHash !=
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      ) {
        try {
          const data = await getJsonDataFromCID(
            hashToCID(template.templateHash)
          );
          return {
            templateHash: template.templateHash,
            name: template.name,
            status: Number(template.status),
            index: Number(template.index),
            sessions: [],
            data,
          };
        } catch (e) {
          console.log(e);
          return null;
        }
      } else return null;
    },
    { concurrency: CONCURRENCY_LIMIT } // Only CONCURRENCY_LIMIT promises will be pending at the same time
  );

  templates = templates.filter((el) => el != null);

  // retrieve badge bot sessions
  const sessions = (
    await getSessionsForTemplates(
      templates.map((template) => template.templateHash)
    )
  ).data;

  for (let session of sessions) {
    const templateIndex = templates.findIndex(
      (template) =>
        template.templateHash.replace('0x', '') == session.templateHash
    );
    templates[templateIndex].sessions.push(session);
  }
  return templates;
}

export async function createMydidTransaction(from, methodName, ...args) {
  const inputData = mydidContract.methods[methodName](...args).encodeABI();

  const gasPrice = parseInt(await web3.eth.getGasPrice());
  const estimateGas = await mydidContract.methods[methodName](
    ...args
  ).estimateGas({ from });

  const rawTransaction = {
    from,
    to: mydidContract.options.address,
    value: '0x0',
    gasPrice: '0x' + gasPrice.toString(16),
    gas: '0x' + Math.round(Number(estimateGas) * 1.01).toString(16),
    data: inputData,
    chainId: process.env.VUE_APP_CHAIN_ID,
  };

  return rawTransaction;
}

export async function getDID(addr) {
  return await mydidContract.methods.getDID(addr).call();
}

export async function isIssuer(addr) {
  return await mydidContract.methods
    .hasRole(createKeccakHash('keccak256').update('ISSUER_ROLE').digest(), addr)
    .call();
}

export async function isBroadcaster(addr) {
  return await mydidContract.methods
    .hasRole(
      '0x' +
        createKeccakHash('keccak256').update('BROADCASTER_ROLE').digest('hex'),
      addr
    )
    .call();
}

export async function isAdmin(addr) {
  const adminAddr = await mydidContract.methods.owner().call();
  return addr == adminAddr;
}

export async function getVCStatus(hash) {
  return await mydidContract.methods.vcs(hash).call();
}

export async function getIssuerCategory(addr) {
  return await mydidContract.methods.issuerCategory(addr).call();
}

export async function getPublicVCsByType(addr, type) {
  const vcNumber = await mydidContract.methods
    .getLengthOfPublicVcsByType(addr, type)
    .call();
  let vcs = [];
  for (var i = 0; i < vcNumber; i++) {
    const vc = await mydidContract.methods
      .publicVcsByType(addr, type, i)
      .call();
    vcs.push(vc);
  }
  return vcs;
}
