import { createStore } from 'vuex';
import { getTemplateList } from '../plugins/utils/mydid/index';

const store = createStore({
  state: {
    currentIssuerDid: null,
    currentIssuerInfo: null,
    currentAddress: null,
    currentPublicKeyBase58: null,
    currentChainId: null,
    isCurrentChainIdValid: false,

    displayIssuerConnection: false,
    displayVerticalBars: true,
    tourOngoing: false,

    basicTemplateList: null,
    basicTemplateListLoading: null,
    communityTemplateList: null,
    communityTemplateListLoading: null,
    participationTemplateList: null,
    participationTemplateListLoading: null,
    membershipTemplateList: null,
    membershipTemplateListLoading: null,
    roleTemplateList: null,
    roleTemplateListLoading: null,
  },
  mutations: {
    setCurrentIssuerDid(state, payload) {
      state.currentIssuerDid = payload;
      state.displayIssuerConnection = false;
    },
    setCurrentIssuerInfo(state, payload) {
      state.currentIssuerInfo = payload;
    },
    setCurrentAddress(state, payload) {
      state.currentAddress = payload;
    },
    setCurrentPublicKeyBase58(state, payload) {
      state.currentPublicKeyBase58 = payload;
    },
    setCurrentChainId(state, payload) {
      state.currentChainId = payload;
      state.isCurrentChainIdValid =
        payload == Number(process.env.VUE_APP_CHAIN_ID);
    },
    resetIssuerState(state) {
      state.currentIssuerDid = null;
      state.currentAddress = null;
      state.currentPublicKeyBase58 = null;
      state.currentIssuerInfo = null;
      state.currentChainId = null;
      state.isCurrentChainIdValid = false;
    },
    setIssuerConnectionDisplay(state, payload) {
      state.displayIssuerConnection = payload;
    },
    setVerticalBarsDisplay(state, payload) {
      state.displayVerticalBars = payload;
    },
    setTourOngoing(state, payload) {
      state.tourOngoing = payload;
    },
    setBasicTemplateList(state, payload) {
      state.basicTemplateList = payload;
    },
    setBasicTemplateListLoading(state, payload) {
      state.basicTemplateListLoading = payload;
    },
    setCommunityTemplateList(state, payload) {
      state.communityTemplateList = payload;
    },
    setCommunityTemplateListLoading(state, payload) {
      state.communityTemplateListLoading = payload;
    },
    setParticipationTemplateList(state, payload) {
      state.participationTemplateList = payload;
    },
    setParticipationTemplateListLoading(state, payload) {
      state.participationTemplateListLoading = payload;
    },
    setMembershipTemplateList(state, payload) {
      state.membershipTemplateList = payload;
    },
    setMembershipTemplateListLoading(state, payload) {
      state.membershipTemplateListLoading = payload;
    },
    setRoleTemplateList(state, payload) {
      state.roleTemplateList = payload;
    },
    setRoleTemplateListLoading(state, payload) {
      state.roleTemplateListLoading = payload;
    },
  },
  actions: {
    async getBasicTemplateList({ commit, state }) {
      commit('setBasicTemplateListLoading', true);
      const list = await getTemplateList(state.currentAddress, 'Basic');
      commit('setBasicTemplateList', list);
      commit('setBasicTemplateListLoading', false);
    },
    async getCommunityTemplateList({ commit, state }) {
      commit('setCommunityTemplateListLoading', true);
      const list = await getTemplateList(state.currentAddress, 'Community');
      commit('setCommunityTemplateList', list);
      commit('setCommunityTemplateListLoading', false);
    },
    async getParticipationTemplateList({ commit, state }) {
      commit('setParticipationTemplateListLoading', true);
      const list = await getTemplateList(state.currentAddress, 'Participation');
      commit('setParticipationTemplateList', list);
      commit('setParticipationTemplateListLoading', false);
    },
    async getMembershipTemplateList({ commit, state }) {
      commit('setMembershipTemplateListLoading', true);
      const list = await getTemplateList(state.currentAddress, 'Membership');
      commit('setMembershipTemplateList', list);
      commit('setMembershipTemplateListLoading', false);
    },
    async getRoleTemplateList({ commit, state }) {
      commit('setRoleTemplateListLoading', true);
      const list = await getTemplateList(state.currentAddress, 'Role');
      commit('setRoleTemplateList', list);
      commit('setRoleTemplateListLoading', false);
    },
    async getAllTemplateLists({ dispatch }) {
      dispatch('getBasicTemplateList');
      dispatch('getCommunityTemplateList');
      dispatch('getParticipationTemplateList');
      dispatch('getMembershipTemplateList');
      dispatch('getRoleTemplateList');
    },
  },
  modules: {},
  getters: {
    getCurrentIssuerDid(state) {
      return state.currentIssuerDid;
    },
    getCurrentIssuerInfo(state) {
      return state.currentIssuerInfo;
    },
    getCurrentAddress(state) {
      return state.currentAddress;
    },
    getCurrentPublicKeyBase58(state) {
      return state.currentPublicKeyBase58;
    },
    getCurrentChainId(state) {
      return state.currentChainId;
    },
    isCurrentChaindIdValid(state) {
      return state.isCurrentChainIdValid;
    },
    isIssuerConnectionDisplayed(state) {
      return state.displayIssuerConnection;
    },
    areVerticalBarsDisplayed(state) {
      return state.displayVerticalBars;
    },
    isTourOngoing(state) {
      return state.tourOngoing;
    },
    getBasicTemplateList(state) {
      return state.basicTemplateList;
    },
    getBasicTemplateListLoading(state) {
      return state.basicTemplateListLoading;
    },
    getCommunityTemplateList(state) {
      return state.communityTemplateList;
    },
    getCommunityTemplateListLoading(state) {
      return state.communityTemplateListLoading;
    },
    getParticipationTemplateList(state) {
      return state.participationTemplateList;
    },
    getParticipationTemplateListLoading(state) {
      return state.participationTemplateListLoading;
    },
    getMembershipTemplateList(state) {
      return state.membershipTemplateList;
    },
    getMembershipTemplateListLoading(state) {
      return state.membershipTemplateListLoading;
    },
    getRoleTemplateList(state) {
      return state.roleTemplateList;
    },
    getRoleTemplateListLoading(state) {
      return state.roleTemplateListLoading;
    },
  },
});

export default store;
