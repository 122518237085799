import bs58 from 'bs58';
import Axios from 'axios';

export async function getJsonDataFromUrl(url) {
  if (
    !url.startsWith('https://myntfsid.mypinata.cloud/') &&
    !url.startsWith('https://resolver.balota.fr/')
  )
    throw 'Bad URL input for method : getJsonDataFromUrl';
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(function (response) {
        return response.arrayBuffer();
      })
      .then(function (buffer) {
        const decoder = new TextDecoder('utf-8');
        const text = decoder.decode(buffer);
        resolve(JSON.parse(text));
      })
      .catch((err) => {
        if (!url.startsWith('https://myntfsid.mypinata.cloud/')) reject(err);

        // retry with secondary ipfs gateway
        fetch(
          url.replace('https://myntfsid.mypinata.cloud/', 'https://ipfs.io/')
        )
          .then(function (response) {
            return response.arrayBuffer();
          })
          .then(function (buffer) {
            const decoder = new TextDecoder('utf-8');
            const text = decoder.decode(buffer);
            resolve(JSON.parse(text));
          })
          .catch((err) => {
            reject(err);
          });
      });
  });
}

export async function getJsonDataFromCID(cid) {
  return new Promise((resolve, reject) => {
    fetch('https://myntfsid.mypinata.cloud/ipfs/' + cid)
      .then(function (response) {
        return response.arrayBuffer();
      })
      .then(function (buffer) {
        const decoder = new TextDecoder('utf-8');
        const text = decoder.decode(buffer);
        resolve(JSON.parse(text));
      })
      .catch((err) => {
        // retry with secondary ipfs gateway
        fetch('https://myntfsid.mypinata.cloud/ipfs/' + cid)
          .then(function (response) {
            return response.arrayBuffer();
          })
          .then(function (buffer) {
            const decoder = new TextDecoder('utf-8');
            const text = decoder.decode(buffer);
            resolve(JSON.parse(text));
          })
          .catch((err) => {
            reject(err);
          });
      });
  });
}

export function hashToCID(hash) {
  const cleanHash = (hash + '').replace('0x', '');
  const bytes = Buffer.from('1220' + cleanHash, 'hex');
  const cid = bs58.encode(bytes);
  return cid;
}

export function getUrlFromCID(cid) {
  return 'https://myntfsid.mypinata.cloud/ipfs/' + cid;
}

export async function uploadIpfsFile(file) {
  var data = new FormData();
  data.append('file', file);

  var config = {
    method: 'post',
    url: 'https://api.pinata.cloud/pinning/pinFileToIPFS',
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${process.env.VUE_APP_PINATA_AUTH}`,
    },
    data,
  };
  const res = await Axios(config);
  const hash = bs58
    .decode(res.data.IpfsHash.substring(0, 46))
    .toString('hex')
    .substring(4);
  return hash;
}

export async function uploadIpfsJsonData(data) {
  var config = {
    method: 'post',
    url: 'https://api.pinata.cloud/pinning/pinJSONToIPFS',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.VUE_APP_PINATA_AUTH}`,
    },
    data,
  };
  const res = await Axios(config);
  const hash = bs58
    .decode(res.data.IpfsHash.substring(0, 46))
    .toString('hex')
    .substring(4);
  return hash;
}
